import * as u from "./utility";
import { Msg } from "./utility";

export const getSourceUrl = (item: any): string | undefined => {
  if (item.st_url) {
    return item.st_url;
  }

  if (isGithub(item)) {
    if ("pull_request" in item) {
      return item.pull_request.html_url;
    } else if ("commits" in item) {
      const c = item.commits[item.commits.length - 1];
      return c.html_url || c.url;
    } else if ("comment" in item) {
      return item.comment.html_url;
    } else if ("ref_type" in item && item["ref_type"] === "branch") {
      return item.repository.html_url + "/tree/" + item.ref;
    } else if ("issue" in item) {
      return item.issue.html_url;
    }
  } else if (isGitlab(item)) {
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      return c.url || c.web_url;
    } else if (item.merge_request) {
      return item.merge_request.url;
    } else if (item.object_attributes) {
      return item.object_attributes.url;
    }
  }

  return undefined;
};

export const isGithub = (item: any): boolean => {
  if (item.st_source === "github" || item.source === "github") {
    return true;
  }
  if ("repository" in item && "html_url" in item.repository) {
    return true;
  }

  return false;
};

export const isGitlab = (item: any): boolean => {
  if (item.st_source === "gitlab") {
    return true;
  }

  if (
    item.repository &&
    item.repository.homepage &&
    item.repository.homepage.includes("gitlab")
  ) {
    return true;
  }

  if (
    item.project &&
    item.project.web_url &&
    item.project.web_url.includes("gitlab")
  ) {
    return true;
  }

  return false;
};

function stringifyPreparsed(item: any[]): string {
  let res = "";
  for (let i of item) {
    if (i.t === "br") {
      res += "\n";
    } else if (i.t === "q") {
      res += stringifyPreparsed(i.v);
    } else if (i.v && typeof i.v === "string") {
      res += i.v;
    }
  }
  return res;
}

export function rebuildMessageText(item: any): string {
  const raw = getRawMessageText(item);
  if (raw.length > 0) {
    return raw;
  }

  if (item.st_pp) {
    const pp = JSON.parse(item.st_pp);
    let res = stringifyPreparsed(pp);
    return res;
  }

  return "";
}

export function getRawMessageText(item: any): string {
  if (item.st_desc) {
    return item.st_desc;
  } else if (item.text) {
    return item.text;
  }

  if (item.subtype) {
    if (item.subtype === "message_changed") {
      if (item.message.subtype === "tombstone") {
        return (
          "Message deleted: " +
          ("previous_message" in item && "text" in item.previous_message
            ? item.previous_message.text
            : "")
        );
      }

      return item.message.text;
    } else if (item.subtype === "message_deleted") {
      if ("previous_message" in item) {
        if ("text" in item.previous_message && item.previous_message.text) {
          return `Message deleted: ${item.previous_message.text}`;
        } else if (
          "files" in item.previous_message &&
          item.previous_message.files.length > 0
        ) {
          return `File deleted: ${item.previous_message.files[0].name}`;
        }
      }
      return "Message deleted";
    }
  }

  if (isGithub(item)) {
    if ("pull_request" in item) {
      const pr = item.pull_request;
      if (item.action) {
        return `Pull request #${item.number} ${item.action}: ${pr.title}`;
      } else {
        let desc = `Pull request #${pr.number}: ${pr.title}`;
        if (pr.body) {
          desc += `\n\n${pr.body}\n`;
        }

        if (item.commits && item.commits.length > 0) {
          desc += "\n\nCommits:\n";
          for (const commit of item.commits) {
            const message = commit.message || commit.commit.message;
            const hash: string = (commit.id || commit.sha).slice(0, 7);
            desc += `${hash} ${message}\n`;
          }
        }
        if (item.comments && item.comments.length > 0) {
          desc += "\n\nComments:\n";
          for (const comment of item.comments) {
            const body = comment.body;
            const user = comment.user.login;
            const updated_at = comment.updated_at;
            desc += `\n"${body}" by ${user} @ ${updated_at}`;
          }
        }

        return desc;
      }
    }

    if ("commits" in item && item.commits.length > 0) {
      const len = item.commits.length;
      const postfix = len === 2 ? "" : "s";
      const c = item.commits[len - 1];
      const message = c.message || c.commit.message;
      return message + (len > 1 ? ` (+${len - 1} other${postfix})` : "");
    }

    if ("comment" in item && "pull_request" in item) {
      return `PR commented: ${item.comment.body}`;
    }

    if ("ref_type" in item) {
      return `${item.ref_type} ${item.ref}`;
    }

    if ("issue" in item) {
      const issue = item.issue;
      if ("comment" in item) {
        return `commented: issue #${issue.number} ${item.comment.body}`;
      }

      if ("action" in item) {
        if (item.action === "opened") {
          return `${item.action}: issue #${issue.number} ${issue.title}\n${issue.body}`;
        } else {
          return `${item.action}: issue #${issue.number} ${issue.title}`;
        }
      }

      let desc = `Issue #${issue.number}: ${issue.title}`;
      if (issue.body) {
        desc += `\n\n${issue.body}\n`;
      }

      if (item.comments && item.comments.length > 0) {
        desc += "\n\nComments:\n";
        for (const comment of item.comments) {
          const body = comment.body;
          const user = comment.user.login;
          const updated_at = comment.updated_at;
          desc += `\n"${body}" by ${user} @ ${updated_at}`;
        }
      }

      return desc;
    }
  } else if (isGitlab(item)) {
    if ("commits" in item && item.commits.length) {
      const len = item.commits.length;
      const postfix = len === 2 ? "" : "s";
      const message = item.commits[len - 1].title;
      return message + (len > 1 ? ` (+${len - 1} other${postfix})` : "");
    }

    if ("object_kind" in item) {
      if (item.object_kind === "merge_request") {
        const action = item.object_attributes.action;
        const title = item.object_attributes.title;
        const iid = item.object_attributes.iid;
        return `MR ${action}: ${title} !${iid}`;
      } else if (item.object_kind === "note") {
        if ("merge_request" in item) {
          return `MR commented: ${item.object_attributes.note}`;
        } else {
          return `commented: ${item.object_attributes.note}`;
        }
      } else if (item.object_kind === "issue") {
        const iid = item.object_attributes.iid;
        const title = item.object_attributes.title;
        const desc = item.object_attributes.description;
        if (item.object_attributes.action === "open") {
          return `issue #${iid}: ${title}\n${desc}`;
        } else {
          return `${item.object_attributes.action} issue #${iid}`;
        }
      }
    }
  }

  return "";
}

export const getShortDescription = (item: any): string => {
  let res = "";
  if ("SK4" in item) {
    res += u.getStoryKey(item) + " ";
  }
  res += rebuildMessageText(item);
  return res;
};

export const isPinned = (item: { SK2?: string }): boolean => {
  return item.SK2 && item.SK2.startsWith("pin:") ? true : false;
};

export const hasNotification = (item: {
  st_ui?: { notif?: any[] };
}): boolean => {
  return item.st_ui && item.st_ui.notif && item.st_ui.notif.length > 0
    ? true
    : false;
};

export const hasNotificationToday = (item: {
  st_ui?: { notif?: any[] };
}): Date | undefined => {
  if (hasNotification(item)) {
    for (let notif_date of item.st_ui!.notif!) {
      const date = u.parsePythonTimestamp(notif_date);
      if (u.isTodayDate(date)) {
        return date;
      }
    }
  }
  return undefined;
};

export const hasFutureNotification = (item: Msg): Date | undefined => {
  const today = u.todayRange();
  if (hasNotification(item)) {
    for (let notif_date of item.st_ui.notif) {
      const date = u.parsePythonTimestamp(notif_date);
      if (date > today[1]) {
        return date;
      }
    }
  }
  return undefined;
};

export function getTimestamp(item: any): string {
  if (isGithub(item)) {
    if ("head_commit" in item) {
      return item.head_commit.timestamp;
    }

    if ("pull_request" in item && item["action"] === "opened") {
      return item.pull_request.created_at;
    }

    if ("pull_request" in item && item["action"] === "closed") {
      return item.pull_request.closed_at;
    }

    if ("pull_request" in item) {
      return item.pull_request.updated_at;
    }

    if ("comment" in item) {
      return item.comment.created_at;
    }

    if ("issue" in item && item["action"] === "opened") {
      return item.issue.created_at;
    }

    if ("issue" in item && item["action"] === "opened") {
      return item.issue.created_at;
    }

    if ("issue" in item && item["action"] === "closed") {
      return item.issue.closed_at;
    }

    if ("issue" in item) {
      return item.issue.updated_at;
    }

    if ("commits" in item && item.commits.length > 0) {
      const c = item.commits[0];
      return c.timestamp || c.commit.author.date || "";
    }
  } else if (isGitlab(item)) {
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      return c.timestamp || c.created_at || c.authored_data || c.committed_data;
    }
    if ("object_attributes" in item) {
      if ("updated_at" in item.object_attributes)
        return item.object_attributes.updated_at;

      if ("timestamp" in item.object_attributes) {
        return item.object_attributes.timestamp;
      }
    }
  }

  // topstorie and slack
  if ("st_created_at" in item) {
    return u.timestampToString(item.st_created_at);
  } else if ("ts" in item) {
    return u.timestampToString(item.ts);
  }
  if ("event_ts" in item) {
    return u.timestampToString(item.event_ts);
  }

  return "";
}

export function getUser(item: any): string {
  if ("st_user" in item && "st_source" in item) {
    return `${item.st_source}#${item.st_user}`;
  }

  if (isGitlab(item)) {
    if (item.user) {
      return item.user.name;
    }
    if (item.commits && item.commits.length > 0) {
      const c = item.commits[item.commits.length - 1];
      if (c.author && typeof c.author == "string") {
        return c.author;
      } else if (c.author && c.author.name) {
        return c.author.name;
      }

      return (
        c.author_name || c.author_email || c.committer_name || c.committer_email
      );
    }

    if ("user_name" in item) {
      return item.user_name;
    }
  } else if (isGithub(item)) {
    if ("user" in item) {
      return item.user;
    }

    if ("commits" in item && item.commits.length) {
      if (item.commits.length > 0) {
        const c = item.commits[item.commits.length - 1];
        if (c.author) {
          return c.author.name || c.author.login;
        }
        if (c.commit) {
          return c.commit.author.name;
        }
      }
    }

    if ("sender" in item) {
      return item.sender.login;
    }

    if ("pusher" in item) {
      return item.pusher.name;
    }

    if (item.comments && item.comments.length > 0) {
      const last_comment = item.comments[item.comments.length - 1];
      return last_comment.user.login;
    }

    if (item.issue && item.issue.user) {
      return item.issue.user.login;
    }

    if (item.pull_request && item.pull_request.user) {
      return item.pull_request.user.login;
    }
  } else {
    // slack
    if ("user" in item) {
      return item.user;
    }

    if ("message" in item && "user" in item.message) {
      return item.message.user;
    }

    if ("message" in item && "edited" in item.message) {
      return item.message.edited.user;
    }
  }

  const src = item.st_source;
  if (src) {
    return src === "auto" ? "automatic" : src;
  }

  return "";
}
