import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ErrorIcon from "@mui/icons-material/Error";
import LinearProgress from "@mui/material/LinearProgress";

import { ImageBox } from "./ImageBox";
import { Pdf, MeddledFile } from "./Pdf";
import { TextPreview } from "./TextPreview";

import { useEffect, useState } from "react";
import { MAX_FILE_SIZE_PREVIEW } from "./constants";

import languageEncoding from "detect-file-encoding-and-language";

export function isTextMimeType(mimetype?: string) {
  return (
    mimetype &&
    (mimetype.startsWith("text/") ||
      mimetype === "application/json" ||
      mimetype === "application/x-csh" ||
      mimetype === "application/x-sh" ||
      mimetype === "application/xml" ||
      mimetype === "application/javascript" ||
      mimetype === "application/x-javascript")
  );
}

const isImage = (f: File) => {
  return f.type.startsWith("image/");
};

const isPdf = (f: File) => {
  return f.type === "application/pdf";
};

type FileUploadPreviewProps = {
  item?: { error?: string };
  files: File[];
  onDelete?: (index: number) => void;
};

export function FileUploadPreview({
  item,
  files,
  onDelete,
}: FileUploadPreviewProps) {
  return (
    <Grid
      container
      direction={"row"}
      justifyItems={"flex-start"}
      alignItems={"flex-start"}
    >
      {files &&
        files.map((f: File, index: number) => (
          <Grid item key={`${f.name}-${f.size}`}>
            <SingleFilePreview
              showStatus={
                item && !onDelete
                  ? item.error
                    ? "error"
                    : "progress"
                  : undefined
              }
              file={f}
              onDelete={onDelete ? () => onDelete(index) : undefined}
            />
          </Grid>
        ))}
    </Grid>
  );
}

type TextFilePreviewProps = {
  file: File;
  onError?: () => void;
};

function TextFilePreview({ file, onError }: TextFilePreviewProps) {
  const [data, setData] = useState<string | ArrayBuffer | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setData(reader.result);
      };
      reader.readAsArrayBuffer(file);
    }
  }, [file]);

  return (
    <>
      {data && (
        <TextPreview
          file={{ mimetype: file.type, name: file.name, index: 9999 }}
          data={data}
          disableControls
          fontSize={11}
          onError={onError}
        />
      )}
    </>
  );
}

export const isTextFileType = (f: { type: string }): boolean => {
  if (isTextMimeType(f.type)) {
    return true;
  }
  return false;
};

type SingleFilePreviewProps = {
  file: File;
  onDelete?: (file: File) => void;
  showStatus?: string;
};

function SingleFilePreview({
  file,
  onDelete,
  showStatus,
}: SingleFilePreviewProps) {
  const [hover, setHover] = useState(false);
  const [textError, setTextError] = useState(false);
  const [isTextFile, setTextFile] = useState<boolean>(false);

  useEffect(() => {
    if (isTextFileType(file)) {
      setTextFile(true);
    }

    // if there's no mime-type we'll try to determine encoding ourself
    // but only if the file is not large
    if (file && !file.type && file.size < MAX_FILE_SIZE_PREVIEW) {
      const determineEncoding = async () => {
        const buffer = await file.arrayBuffer();
        let encoding = (await languageEncoding(new Blob([buffer]))).encoding;
        if (encoding === "UTF-8") {
          setTextFile(true);
          (file as MeddledFile).detected_mimetype = "text/plain";
        }
      };

      determineEncoding();
    }
  }, [file]);

  // useEffect(() => {
  //   console.log("RERENDER FILES");
  // });

  const isUnknown = (f: File) => {
    return !isImage(f) && !isPdf(f) && !isTextFile;
  };

  const handleError = () => {
    setTextError(true);
  };

  return (
    <Box
      component="span"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box sx={{ position: "relative", display: "inline-block" }}>
        {onDelete && hover && (
          <Box sx={{ position: "absolute", right: 0, zIndex: 5 }}>
            <IconButton onClick={() => onDelete(file)} size="small">
              <CancelIcon
                fontSize="inherit"
                sx={{ borderRadius: "50%", background: "white" }}
              />
            </IconButton>
          </Box>
        )}
        {showStatus === "error" && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: 5,
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ErrorIcon sx={{ color: "red" }} />
          </Box>
        )}
        {showStatus === "progress" && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: 5,
            }}
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box sx={{ width: "97%", m: 0.1 }}>
              <LinearProgress
                color="secondary"
                sx={{
                  backgroundColor: "lightgrey",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "grey",
                  },
                }}
              />
            </Box>
          </Box>
        )}
        {file.size < MAX_FILE_SIZE_PREVIEW && (
          <>
            {isImage(file) && (
              <ImageBox
                sx={{
                  m: 0.1,
                  maxHeight: "120px",
                  maxWidth: "100%",
                  width: "auto",
                  height: "auto",
                  borderRadius: "7px",
                }}
                url={URL.createObjectURL(file)}
                name={file.name}
              />
            )}
            {isPdf(file) && <Pdf file={file} />}
            {isTextFile && !textError && (
              <TextFilePreview file={file} onError={handleError} />
            )}
          </>
        )}
        {(file.size >= MAX_FILE_SIZE_PREVIEW ||
          isUnknown(file) ||
          textError) && (
          <Stack>
            <InsertDriveFileIcon />
            <Typography fontSize={7}>{file.name}</Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
