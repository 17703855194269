import React, { useEffect, useState, useRef } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";

type PasswordTextFieldProps = {
  autoFocus?: boolean;
  value: string;
  label: string;
  onChange: (password: string) => void;
  onEnter: () => void;
  onTab?: () => void;
  inputRef?: any;
  error?: any;
};

function PasswordTextField({
  autoFocus,
  value,
  label,
  onChange,
  onEnter,
  onTab,
  inputRef,
  error,
}: PasswordTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(value);
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleBlur = () => {};

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const value = (e.target as any).value;
    setPassword(value);
    onChange(value);
  };

  const handleKey = (e: React.KeyboardEvent<HTMLElement>) => {
    if (onEnter) {
      if (e.key === "Enter") {
        e.preventDefault();
        onEnter();
      } else if (e.key === "Tab" && onTab) {
        e.preventDefault();
        onTab();
      }
    }
  };

  return (
    <TextField
      style={{ width: "12ch" }}
      variant="standard"
      label={label}
      value={password}
      size="small"
      color="primary"
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKey}
      autoFocus={autoFocus}
      inputRef={inputRef}
      type="text"
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              sx={{ color: "white" }}
              size="small"
            >
              {showPassword ? (
                <VisibilityOff sx={{ fontSize: 13 }} />
              ) : (
                <Visibility sx={{ fontSize: 13 }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        input: {
          color: "white",
          ...(showPassword
            ? {}
            : {
                textSecurity: "disc",
                MozTextSecurity: "disc",
                WebkitTextSecurity: "disc",
              }),
        },
      }}
      InputLabelProps={{
        style: {
          color: "white",
        },
      }}
    />
  );
}

export default function useClickOutside(): [
  React.MutableRefObject<undefined | HTMLElement>,
  boolean
] {
  const [clicked, setClicked] = useState(false);
  const ref = useRef<HTMLElement>();

  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setClicked(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  return [ref, clicked];
}

type PasswordInputProps = {
  password?: string;
  onPassword: (password: string) => void;
  onCancel: () => void;
  confirm: boolean;
  label: string;
  error?: any;
};

export function PasswordInput({
  password,
  onPassword,
  onCancel,
  confirm,
  label,
  error,
}: PasswordInputProps) {
  const [password1, setPassword1] = useState<string>(password || "");
  const [password2, setPassword2] = useState<string>(password || "");
  const [ref, clickedOutside] = useClickOutside();
  const inputRef = useRef<HTMLElement>();

  const handlePassword1 = (value: string) => {
    setPassword1(value);
  };

  const handlePassword2 = (value: string) => {
    setPassword2(value);
  };

  const handleOk = () => {
    if (canDelete()) {
      onPassword("");
    } else if (password1 && (!confirm || password1 === password2)) {
      onPassword(password1);
    }
  };

  const canDelete = () => {
    return password && password === password1 && password === password2;
  };

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        onCancel();
      }
    };

    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  useEffect(() => {
    if (clickedOutside) {
      onCancel();
    }
  }, [clickedOutside]);

  const handleEnter1 = () => {
    if (!confirm && password1) {
      onPassword(password1);
    }

    if (confirm && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleEnter2 = () => {
    if (!canDelete() && password1 && password1 === password2) {
      onPassword(password1);
    }
  };

  return (
    <Box
      ref={ref}
      sx={{
        backgroundColor: "#606060",
        color: "white",
        borderRadius: "5px",
        pb: 3,
        pt: 2,
        pl: 2,
        pr: 2,
        display: "inline-block",
        width: "170px",
      }}
    >
      <Stack direction="column" gap={1} alignItems={"center"}>
        <Box sx={{ color: "lightgrey", fontSize: 10, wordBreak: "normal" }}>
          {label}
        </Box>
        <PasswordTextField
          error={error}
          value={password1}
          label={"Password"}
          onChange={handlePassword1}
          onEnter={handleEnter1}
          onTab={handleEnter1}
          autoFocus
        />
        {confirm && (
          <PasswordTextField
            error={error}
            value={password2}
            label={"Confirm"}
            onChange={handlePassword2}
            onEnter={handleEnter2}
            inputRef={inputRef}
          />
        )}
        <Stack direction={"row"} sx={{ mt: 2 }} gap={1}>
          <PopupButton size="small" onClick={onCancel}>
            Cancel
          </PopupButton>
          <PopupButton
            size="small"
            disabled={!(password1 && (!confirm || password1 === password2))}
            onClick={handleOk}
          >
            {canDelete() ? "Delete" : "OK"}
          </PopupButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export const PopupButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[700],
  "&:hover": {
    backgroundColor: grey[500],
  },
  fontSize: 8,
}));
