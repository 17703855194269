import React, { ChangeEvent, useEffect, useState, CSSProperties } from "react";

// import { getUniqueKey } from "@react-sigma/core/lib/utils";
import { useSigma } from "@react-sigma/core";
import { useCamera } from "@react-sigma/core";
// import { useRegisterEvents } from "../../hooks/useRegisterEvents";
import { useRegisterEvents } from "@react-sigma/core";
import { AutocompleteStoryInput } from "./AutocompleteStory";

export function getUniqueKey() {
  return Math.random().toString(36).slice(2);
}

type GraphEdge = [string, string];

export type GraphData = {
  edges: GraphEdge[];
  nodes: { [storyId: string]: [number, string] };
};

/**
 * The `SearchControl` create an input text where user can search a node in the graph by its label.
 * There is an autocomplete based on includes & lower case.
 * When a node is found, the graph will focus on the highlighted node
 *
 * ```jsx
 * <SigmaContainer>
 *   <ControlsContainer>
 *     <SearchControl />
 *   </ControlsContainer>
 * </SigmaContainer>
 * ```
 * See [[SearchControlProps]] for more information.
 *
 * @category Component
 */
export const GraphSearchControl = ({ data }: { data?: GraphData }) => {
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const { gotoNode } = useCamera();
  const [selected, setSelected] = useState<string>();
  const [options, setOptions] = useState<{ [key: string]: string }>({}); // Map<string, string>

  useEffect(() => {
    setSelected(undefined);
  }, [data]);

  useEffect(() => {
    registerEvents({
      clickStage: () => {
        setSelected(undefined);
      },
    });
  }, [registerEvents]);

  useEffect(() => {
    if (!selected) {
      return;
    }

    try {
      sigma.getGraph().setNodeAttribute(selected, "highlighted", true);
      gotoNode(selected);

      return () => {
        try {
          sigma.getGraph().setNodeAttribute(selected, "highlighted", false);
        } catch (e) {}
      };
    } catch (e) {
      return;
    }
  }, [selected]);

  useEffect(() => {
    if (data && data.nodes) {
      let res: { [key: string]: string } = {};
      for (let [key, v] of Object.entries(data.nodes)) {
        const [_, caption] = v;
        res[caption] = key;
      }
      setOptions(res);
    }
  }, [data]);

  const handleSelected = (val: string) => {
    let found = null;
    sigma.getGraph().forEachNode((key, attributes) => {
      if (attributes.label === val) {
        found = key;
      }
    });
    if (found) {
      setSelected(found);
    }
  };

  return (
    <AutocompleteStoryInput
      placeholderText="Search"
      onSelected={handleSelected}
      onHighlight={handleSelected}
      variant={"standard"}
      options={options}
    />
  );
};
