import { useState, useEffect } from "react";

import { useAtom } from "jotai";

import store from "store2";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

import { projectPickerState } from "./JotaiAtoms";
import { refreshToken } from "./Auth";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import log from "./logger";
import * as u from "./utility";
import { useHttpApiPost } from "./hooks";

import { useParams } from "react-router-dom";

type NewProjectDialogProps = {
  open: boolean;
  onClose: (project?: string) => void;
};

function NewProjectDialog({ open, onClose }: NewProjectDialogProps) {
  const [project, setProject] = useState("");

  const handleClose = (newProject?: string) => {
    if (onClose) {
      onClose(newProject);
    }
    setProject("");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProject(e.target.value);
  };

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <DialogTitle>Create new project</DialogTitle>

      <Stack gap={1}>
        <TextField
          onChange={handleChange}
          defaultValue={project}
          label="Project name"
          variant="outlined"
          margin="normal"
        ></TextField>
        <Stack direction="row">
          <Button onClick={() => handleClose(project)}>OK</Button>
          <Button onClick={() => handleClose()}>Cancel</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}

function ProjectPicker() {
  const [projects, setProjects] = useState<string[]>([]);
  const [project, setProject] = useAtom<string>(projectPickerState);
  const [selection, setSelection] = useState<string | number>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const httpPost = useHttpApiPost();

  const { proj } = useParams();
  const updateContent = () => {
    const request = async () => {
      try {
        const response = await httpPost("admin", { action: "projects" });
        let projectList = response.data.projects;

        if (!u.equalArrays(projectList, projects)) {
          setProjects(projectList);
        } else {
          projectList = projects;
        }

        setProject((prev: string) => {
          if (prev && projectList.length) {
            const found = projectList.indexOf(prev);
            if (found !== -1) {
              setSelection(found);
              return prev;
            } else if (!proj) {
              setSelection(0);
              return projectList[0];
            }
          } else if (projectList.length && !proj) {
            setSelection(0);
            return projectList[0];
          } else if (proj) {
            setSelection("");
            return proj;
          }
          return prev;
        });
      } catch (err) {
      } finally {
      }
    };
    request();
  };

  useEffect(() => {
    updateContent();
  }, []);

  useEffect(() => {
    if (project && projects && projects.length > 0) {
      const found = projects.indexOf(project);
      if (found === -1) {
        updateContent();
      } else {
        setSelection(found);
      }
    }
  }, [project, projects]);

  const handleChange = (e: SelectChangeEvent<string | number>) => {
    const sel = Number(e.target.value);
    setSelection(sel);

    if (projects[sel] === project) {
      return;
    }

    store("ProjectPicker:" + window.location.search, projects[sel]);
    window.location.href = u.getProjectUrl(projects[sel]);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setDialogOpen(true);
  };

  const handleClose = (newProject?: string) => {
    const request = async (newProject: string) => {
      try {
        await httpPost("admin", { action: "grant", project: newProject });
        refreshToken(async () => {
          window.location.href = u.getProjectUrl(newProject);
        });
      } catch (err) {
        log.debug(err);
      } finally {
      }
    };

    setDialogOpen(false);

    if (newProject && newProject.length) {
      request(newProject);
    }
  };

  return (
    <Stack direction="row">
      <IconButton onClick={handleClick}>
        <AddCircleIcon />
      </IconButton>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel id="demo-simple-select-label">Project</InputLabel>
        <Select label="Project" onChange={handleChange} value={selection}>
          {projects &&
            projects.map((proj, index) => (
              <MenuItem key={proj} value={index}>
                {proj}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <NewProjectDialog open={dialogOpen} onClose={handleClose} />
    </Stack>
  );
}

export default ProjectPicker;
