import "./App.css";

import { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PushPinIcon from "@mui/icons-material/PushPin";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import Collapse from "@mui/material/Collapse";

import { labelToggleState, projectPickerState } from "./JotaiAtoms";

import * as lodash from "lodash";

import { ButtonLink } from "./Image";

import { StoryLabels } from "./StoryLabels";
import { StoryCaption } from "./StoryCaption";
import { StoryDetails } from "./StoryDetails";
import { MemoMessage } from "./Thread";
import log from "./logger";

import * as u from "./utility";
import PouchDB from "pouchdb-browser";
import { useAtomValue } from "jotai";
import { useHttpPost, useExitToApp } from "./hooks";
import { TimelineChips, StoryChip } from "./TimeLineChips";
import { isPinned } from "./storydat";
import { DummyItem } from "./SendMessageBox";
import { NotifyMessage, sortMessages } from "./SendMessageBox";
import { StoryItem } from "./StoryItem";
import { MessageItem } from "./MessageItem";

type StoryCellProps = {
  item: StoryItem;
  onRestore: (item: StoryItem) => void;
  onPin: (item: StoryItem) => void;
  deleted: boolean;
  expand?: boolean;
  collapse: number;
  mode?: string;
  onUpdateLabels: (storyId: string, labels: string[]) => void;
  onLabelCreated: (labels: string[]) => void;
  setLabelDiff?: (value: React.SetStateAction<Map<string, boolean>>) => void;
  labelDiff: Map<string, boolean>;
  smoothCollapseAnimation?: boolean;
  rowSelected?: boolean;
  hideControls?: boolean;
};

export function StoryCell({
  item,
  onRestore,
  onPin,
  deleted,
  expand,
  collapse,
  mode,
  onUpdateLabels,
  onLabelCreated,
  labelDiff,
  setLabelDiff,
  smoothCollapseAnimation,
  rowSelected,
  hideControls,
}: StoryCellProps) {
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const [connected, setConnected] = useState<StoryItem[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const httpPost = useHttpPost();
  const project = useAtomValue(projectPickerState);
  const exitToApp = useExitToApp();

  const labelToggle = useAtomValue(labelToggleState);

  let ref = useRef<{ id?: string }>({});

  const repliesLabel = (item: StoryItem) => {
    const st_len = item.st_len || 0;
    const num_messages = messages ? messages.length : 0;
    const thread_length = Math.max(st_len, num_messages);
    if (thread_length === 2) {
      // acccount for the thread starter
      return "1 reply";
    } else if (thread_length > 2) {
      return `${thread_length - 1} replies`;
    } else {
      return "reply";
    }
  };

  const getData = async (cached?: MessageItem[], reply?: NotifyMessage) => {
    try {
      const response = await httpPost("stories", {
        messages: true,
        connected: true,
        story: item.SK,
      });

      // const ts = u.getCurrentTimestamp();
      // const hardcoded = [
      //   {
      //     SK: ts,
      //     ts: ts,
      //     event_ts: ts,
      //     type: "message",
      //     st_enc: {
      //       ver: 1,
      //       cipher: "+s+mTDEUY0ljyHIZDhlS+e2N9Rfp8KuopVQx",
      //       salt: "XSnaO+WErpo8zeb+vVSP8TApusJVMZVo1r9iRO5OEOk=",
      //       iv: "kKeCgl0OBSBKEMqM",
      //     },
      //   },
      // ];

      const hardcoded: MessageItem[] = [];

      if (!messages || response.data.messages.length) {
        const newMessages = response.data.messages.reverse().concat(hardcoded);
        const sorted = sortMessages(newMessages, true);

        const updateCache = (sorted: MessageItem[]) => {
          if (project) {
            const db = new PouchDB(project);

            const key = `Thread:${item.PK}:${item.SK}`;
            const doc = { _id: key, messages: sorted };
            db.get(key)
              .then((prev) =>
                db.put({ _rev: prev._rev, ...doc }).catch((e) => {})
              )
              .catch((e) => {
                db.put({ _id: key, message: sorted }).catch((e) => {});
              });
          }
        };

        if (cached) {
          if (
            cached.length !== sorted.length ||
            (cached.length > 0 &&
              cached[cached.length - 1].SK !== sorted[sorted.length - 1].SK)
          ) {
            setMessages(sorted);
            updateCache(sorted);
          } else if (cached.length === sorted.length) {
            for (let i = 0; i < cached.length; ++i) {
              const rhs = cached[i] as MessageItem;
              const lhs = sorted[i] as MessageItem;
              if (
                (rhs.st_ui && !lhs.st_ui) ||
                (!rhs.st_ui && lhs.st_ui) ||
                (rhs.st_ui &&
                  lhs.st_ui &&
                  !lodash.isEqual(rhs.st_ui, lhs.st_ui)) ||
                (rhs.st_hide && !lhs.st_hide) ||
                (!rhs.st_hide && rhs.st_hide)
              ) {
                setMessages(sorted);
                updateCache(sorted);
                break;
              }
            }
          }
        } else {
          setMessages((prev) => {
            if (!prev || !prev.length) {
              updateCache(sorted);
              if (sorted.length > 0) {
                return sorted;
              } else {
                return prev;
              }
            } else {
              if (reply) {
                const found = prev.findIndex(
                  (e) => e.dummy && e.ts === reply.ts
                );
                if (found !== -1) {
                  let res = [...prev];
                  let dummy = res[found];
                  reply.msg.old_dummy_message = dummy;
                  res[found] = reply.msg;
                  return res;
                }
              } else {
                updateCache(sorted);
                return sorted;
              }
              return prev;
            }
          });
        }
      }
      setConnected(response.data.connected);
    } catch (err) {
      log.debug(err);
    } finally {
    }
  };

  const handleExpand = (item: StoryItem) => {
    const panelExpanded = !expanded;
    setExpanded(panelExpanded);

    if (panelExpanded) {
      if (project) {
        const key = `Thread:${item.PK}:${item.SK}`;
        const db = new PouchDB(project);

        db.get(key)
          .then((doc: any) => {
            setMessages(doc.messages);
            getData(doc.messages);
          })
          .catch((err) => getData());
      }
    }
  };

  useEffect(() => {
    if (expand !== expanded) {
      handleExpand(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  useEffect(() => {
    if ("id" in ref.current && ref.current.id !== item.SK) {
      setExpanded(false);
      setMessages([]);
    }
    ref.current.id = item.SK;
  }, [item]);

  useEffect(() => {
    if (expanded) {
      handleExpand(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse]);

  const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    const url = u.getStoryUrl(item, project);

    if (e.metaKey) {
      e.stopPropagation();
      window.open(url, "_blank", "noreferrer");
    } else if (!e.shiftKey) {
      navigator.clipboard.writeText(url);
    }
  };

  const handleRepliesClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.metaKey) {
      return handleLinkClick(e);
    }
    handleExpand(item);
  };

  const handleNewMessage = (msg?: NotifyMessage) => {
    getData(undefined, msg);
  };

  const handleReload = () => {
    getData();
  };

  return (
    <Box>
      <MemoMessage item={item} storyKey={undefined} overflow={undefined} />
      <Box
        sx={{
          "& > :not(style) + :not(style)": {
            ml: 1,
          },
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.2}
          m={0}
          component="span"
        >
          {!hideControls && (
            <>
              {!(item as DummyItem).dummy && (
                <Box sx={{ whiteSpace: "nowrap" }} component="span">
                  <ButtonLink
                    text={repliesLabel(item)}
                    onclick={handleRepliesClick}
                    fontSize={undefined}
                    disabled={undefined}
                  />
                </Box>
              )}
              {true && !deleted && (
                <Tooltip title="Copy link to this message, or open in new tab with Meta+click">
                  <IconButton size="small" onClick={handleLinkClick}>
                    <LinkIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {u.isSlackMessage(item) && (
                <Tooltip title="Open in external app">
                  <IconButton
                    size="small"
                    onClick={() => {
                      exitToApp(item);
                    }}
                  >
                    <ExitToAppIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {true && !deleted && !(item as DummyItem).dummy && (
                <Tooltip title="Pin topic">
                  <IconButton
                    size="small"
                    color={isPinned(item) ? "primary" : "default"}
                    onClick={() => {
                      onPin(item);
                    }}
                  >
                    <PushPinIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {deleted && (
                <Tooltip title="Restore this story">
                  <IconButton
                    size="small"
                    disabled={false}
                    onClick={() => {
                      onRestore(item);
                    }}
                  >
                    <RestoreFromTrashIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          {"SK4" in item && <StoryChip item={item} rowSelected={rowSelected} />}
          <TimelineChips
            item={item}
            forRecentStories={mode === "recent" || mode === "popular"}
          />
        </Stack>
        <Stack direction="row" sx={{ width: "100%" }} justifyContent="flex-end">
          {!labelToggle && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <StoryLabels
                item={item}
                onLabelCreated={onLabelCreated}
                onUpdateLabels={onUpdateLabels}
                minimized={true}
                diff={labelDiff}
                setDiff={setLabelDiff}
                disabled={(item as DummyItem).dummy ? true : false}
              />
            </Box>
          )}
          {!hideControls && !(item as DummyItem).dummy && (
            <StoryCaption item={item} initialValue={item.st_cap || ""} />
          )}
        </Stack>
      </Box>
      {(!smoothCollapseAnimation || expand) && expanded && (
        <Box sx={{ maxWidth: "900px", mb: 4 }}>
          <StoryDetails
            item={item}
            messages={messages}
            setMessages={setMessages}
            connected={connected}
            setConnected={setConnected}
            onNewMessage={handleNewMessage}
            onRequestReload={handleReload}
          />
        </Box>
      )}
      {smoothCollapseAnimation && !expand && (
        <Collapse in={expanded} timeout="auto">
          {expanded && (
            <Box sx={{ maxWidth: "900px", mb: 4 }}>
              <StoryDetails
                item={item}
                messages={messages}
                setMessages={setMessages}
                connected={connected}
                setConnected={setConnected}
                onNewMessage={handleNewMessage}
                onRequestReload={handleReload}
              />
            </Box>
          )}
        </Collapse>
      )}
    </Box>
  );
}
