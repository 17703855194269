import { argon2id } from "hash-wasm";
import { Cipher } from "./Cipher";

function b64ToBytes(base64: string): Uint8Array {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0)!);
}

function bytesToB64(bytes: Uint8Array): string {
  const binString = String.fromCodePoint(...bytes);
  return btoa(binString);
}

function randomValues(length: number): Uint8Array {
  const rnd = new Uint8Array(length);
  window.crypto.getRandomValues(rnd);
  return rnd;
}

async function hashPassword(pass: string, salt: Uint8Array) {
  const hash = await argon2id({
    password: pass,
    salt,
    parallelism: 1,
    iterations: 256,
    memorySize: 4 * 1024,
    hashLength: 32,
    outputType: "binary",
  });

  return hash;
}

export const decrypt = async (
  pass: string,
  message: Cipher
): Promise<string> => {
  if (message.ver !== 1) {
    throw new Error("unsupported cipher");
  }

  const salt = b64ToBytes(message.salt);
  const hash = await hashPassword(pass, salt);

  const key = await window.crypto.subtle.importKey(
    "raw",
    hash.buffer,
    { name: "AES-GCM" },
    false,
    ["decrypt"]
  );
  const iv = b64ToBytes(message.iv);

  const cipher = b64ToBytes(message.cipher);
  const original = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    cipher
  );

  const decoder = new TextDecoder();
  const text = decoder.decode(original);

  return text;
};

export const encrypt = async (
  pass: string,
  message: string
): Promise<Cipher> => {
  const salt = randomValues(32);
  const hash = await hashPassword(pass, salt);

  const iv = randomValues(12);
  const key = await window.crypto.subtle.importKey(
    "raw",
    hash.buffer,
    { name: "AES-GCM" },
    false,
    ["encrypt"]
  );

  const enc = new TextEncoder();
  const encoded = enc.encode(message);

  const cipher = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encoded
  );

  return {
    ver: 1,
    cipher: bytesToB64(new Uint8Array(cipher)),
    salt: bytesToB64(salt),
    iv: bytesToB64(iv),
  };
};
