import "./App.css";

import { useState, useEffect, Fragment } from "react";

import Typography from "@mui/material/Typography";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";

import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { useHttpPost } from "./hooks";

import { labelToggleState } from "./JotaiAtoms";

import { StoryLabels } from "./StoryLabels";
import { StoryCell } from "./StoryCell";
import { hasNotification, isPinned, hasNotificationToday } from "./storydat";

import * as u from "./utility";
import { useAtomValue } from "jotai";
import { getRowColor } from "./TimeLineChips";
import { DummyItem } from "./SendMessageBox";
import { StoryItem } from "./StoryItem";

type StoryCheckboxProps = {
  onChecked: (checked: boolean) => void;
  reload: unknown;
  initialState: boolean;
  disabled: boolean;
};

function StoryCheckbox({
  onChecked,
  reload,
  initialState,
  disabled,
}: StoryCheckboxProps) {
  const [checked, setChecked] = useState<boolean>(false);
  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    const val = (e.target as any).checked;
    setChecked(val);
    onChecked(val);
  };

  useEffect(() => {
    if (checked) {
      setChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    setChecked(initialState);
  }, [initialState]);

  return (
    <Checkbox
      size="small"
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    ></Checkbox>
  );
}

type NotificationBellProps = {
  item: StoryItem;
  sx: any;
  onDelete: (notificationTimestamp: undefined | string) => void;
};

function NotificationBell({ item, sx, onDelete }: NotificationBellProps) {
  let Icon = NotificationsIcon;

  let future: undefined | Date = undefined;
  let past: undefined | Date = undefined;
  let present: undefined | Date = undefined;

  let future_ts: undefined | string = undefined;
  let past_ts: undefined | string = undefined;
  let preset_ts: undefined | string = undefined;

  const today = u.todayRange();
  if (hasNotification(item)) {
    for (let notif_date of item.st_ui.notif) {
      const date: Date = u.parsePythonTimestamp(notif_date);
      if (u.isTodayDate(date)) {
        present = date;
        preset_ts = notif_date;
        break;
      } else if (date > today[1]) {
        if (!future || date < future) {
          future = date;
          future_ts = notif_date;
        }
      } else {
        if (!past || date > past) {
          past = date;
          past_ts = notif_date;
        }
      }
    }
  }

  let tooltip = null;
  let notif_ts: undefined | string = undefined;
  if (present) {
    Icon = NotificationsActiveIcon;
    tooltip = present.toString();
    notif_ts = preset_ts;
  } else if (future) {
    Icon = NotificationsIcon;
    tooltip = future.toString();
    notif_ts = future_ts;
  } else if (past) {
    Icon = NotificationsNoneIcon;
    tooltip = past.toString();
    notif_ts = past_ts;
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(notif_ts);
    }
  };

  return (
    <Tooltip
      title={
        <Fragment>
          <Stack direction="row">
            <Typography fontSize={11}>{tooltip}</Typography>
            {notif_ts !== past_ts && (
              <IconButton
                size="small"
                sx={{ color: "#ffffff" }}
                onClick={handleDelete}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            )}
          </Stack>
        </Fragment>
      }
    >
      {/* <Icon {...props} /> */}
      <Icon color="disabled" sx={sx} fontSize={"small"} />
    </Tooltip>
  );
}

type StoryRowProps = {
  item: StoryItem;
  onPin: any;
  onDelete: any;
  onSelected: (
    event: React.MouseEvent<HTMLElement> | null,
    storyId: string,
    selected: boolean
  ) => void;
  onRestore: any;
  onUpdateLabels: any;
  collapseAll: number;
  onLabelCreated: any;
  mode?: string;
  selectedRow: boolean;
  smoothCollapseAnimation?: boolean;
  expand?: boolean;
};

export function StoryRow({
  item,
  onPin,
  onDelete,
  onSelected,
  onRestore,
  onUpdateLabels,
  collapseAll,
  onLabelCreated,
  mode,
  selectedRow,
  smoothCollapseAnimation,
  expand,
}: StoryRowProps) {
  const [selected, setSelected] = useState<boolean>(false);
  const labelToggle = useAtomValue(labelToggleState);
  const [labelDiff, setLabelDiff] = useState<Map<string, boolean>>(new Map());
  const [notificationDeleted, setNotificationDeleted] =
    useState<boolean>(false);
  const httpPost = useHttpPost();

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.metaKey || e.shiftKey) {
      if ((e.target as any).href) {
        return;
      }
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
      }
      setSelected(!selected);
      if (onSelected) {
        onSelected(e, item.SK, !selected);
      }
    }
  };

  const handleCheckbox = (storyId: string, checked: boolean) => {
    setSelected(checked);
    if (onSelected) {
      onSelected(null, storyId, checked);
    }
  };

  const handleDeleteNotification = (notif_ts: string | undefined) => {
    const request = async () => {
      try {
        httpPost("stories", {
          clearschedule: true,
          story: item.SK,
          notification: notif_ts,
        });
        setNotificationDeleted(true);
      } catch (e) {}
    };
    if (notif_ts) {
      request();
    }
  };

  const getItemStyle = (item: StoryItem) => ({
    background: getRowColor(item, selected),
  });

  const getHighlightColor = (item: StoryItem) => {
    if (isPinned(item)) {
      return "cornflowerblue";
    }
    if (hasNotificationToday(item)) {
      return "lightcoral";
    }

    // if (hasFutureNotification(item)) {
    //   return "lightcoral";
    // }

    return undefined;
  };

  useEffect(() => {
    setSelected(selectedRow);
  }, [item]);

  useEffect(() => {
    setSelected(selectedRow);
  }, [selectedRow]);

  return (
    <TableRow
      style={getItemStyle(item)}
      hover={true}
      sx={{ "&:last-child td, &:last-child th": { border: 0 }, height: "100%" }}
      onClick={(e) => {
        handleClick(e);
      }}
    >
      {true && mode !== "deleted" && (
        <TableCell
          width={1}
          component="th"
          scope="row"
          size="small"
          align="right"
          style={{ verticalAlign: "top" }}
          sx={{ p: 0, height: "100%" }}
        >
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"flex-start"}
            sx={{ height: "100%" }}
            gap={0}
          >
            <StoryCheckbox
              initialState={selected}
              onChecked={(checked) => {
                handleCheckbox(item.SK, checked);
              }}
              disabled={(item as DummyItem).dummy || false}
              reload={undefined}
            />
            {getHighlightColor(item) && (
              <Box
                sx={{
                  width: 15,
                  height: "100%",
                  backgroundColor: getHighlightColor(item),
                  borderRadius: "4px",
                  mb: 1.5,
                }}
              />
            )}
          </Stack>
        </TableCell>
      )}

      {true && (
        <TableCell
          component="th"
          scope="row"
          align="left"
          size="small"
          sx={{
            wordBreak: "break-word",
            whiteSpace: "normal",
            verticalAlign: "top",
            pb: 0,
            pl: mode === "deleted" ? 2 : 0,
            pr: 0,
          }}
        >
          <Stack direction="row" gap={1} sx={{ width: "100%" }}>
            {!notificationDeleted && hasNotification(item) && (
              <NotificationBell
                item={item}
                sx={{ mt: 0.5 }}
                onDelete={handleDeleteNotification}
              />
            )}
            <Box sx={{ width: "100%" }}>
              <StoryCell
                item={item}
                expand={mode === "onestory" || expand}
                collapse={collapseAll}
                onPin={onPin}
                onRestore={onRestore}
                deleted={mode === "deleted"}
                mode={mode}
                onUpdateLabels={onUpdateLabels}
                onLabelCreated={onLabelCreated}
                labelDiff={labelDiff}
                setLabelDiff={setLabelDiff}
                smoothCollapseAnimation={smoothCollapseAnimation}
                rowSelected={selected}
              />
            </Box>
          </Stack>
        </TableCell>
      )}

      {labelToggle && mode !== "deleted" && (
        <TableCell
          width="20%"
          component="th"
          scope="row"
          size="small"
          align="left"
          style={{ verticalAlign: "top" }}
          sx={{ p: 0, pt: 0.2 }}
        >
          <StoryLabels
            item={item}
            onLabelCreated={onLabelCreated}
            onUpdateLabels={onUpdateLabels}
            diff={labelDiff}
            setDiff={setLabelDiff}
            disabled={(item as DummyItem).dummy || false}
          />
        </TableCell>
      )}

      {true && mode !== "deleted" && (
        <TableCell
          width={1}
          component="th"
          scope="row"
          size="small"
          align="left"
          style={{ verticalAlign: "top" }}
          sx={{ pr: 0.5, pt: 0, pb: 0, pl: labelToggle ? 0 : 2 }}
        >
          {true && !(item as DummyItem).dummy && (
            <Tooltip title="Delete story (not permanently)">
              <IconButton
                size="small"
                onClick={() => {
                  onDelete(item);
                }}
              >
                <DeleteOutlineIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
