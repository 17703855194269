import React, { useState, useEffect, useRef } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Autocomplete from "@mui/material/Autocomplete";

import { captionsState } from "./JotaiAtoms";

import { useAtom } from "jotai";
import { useHttpPostAndProject } from "./hooks";

type CaptionButtonInputProps = {
  storyId: string;
  initialValue: string;
  onSelected?: (value?: string) => void;
};

function CaptionButtonInput({
  storyId,
  initialValue,
  onSelected,
}: CaptionButtonInputProps) {
  const [value, setValue] = useState<string | undefined>(initialValue);
  const ref = useRef<string>("");
  const [httpPost, project] = useHttpPostAndProject();

  const [captions, setCaptions] = useAtom(captionsState);

  const handleCaption = (value?: string) => {
    const request = async (value: string) => {
      try {
        httpPost("label", {
          setstoryprops: {
            story: storyId,
            st_cap: value,
          },
        });
        if (value !== "") {
          const proj = project;
          setCaptions((prev: { [key: string]: { [key: string]: string } }) => {
            if (proj in prev) {
              let newCaps = { ...captions };
              newCaps[proj] = { ...prev[proj], [value]: storyId };
              return newCaps;
            }
            return prev;
          });
        }
      } catch (err) {
      } finally {
      }
    };

    if (value || value === "") {
      request(value);
    }
    if (onSelected) {
      onSelected(value);
    }
  };

  const handleKey = (e: React.KeyboardEvent<HTMLElement>) => {
    ref.current = e.key;
    if (e.key === "Enter") {
      e.preventDefault();
      handleCaption(value || "");
    } else if (e.key === "Escape") {
      handleCaption();
    }
  };

  const saveText = (v: string) => {
    if (v) {
      if (v.trimStart().length <= 18) {
        setValue(v.trimStart());
      }
    } else {
      setValue(undefined);
    }
  };

  const handleChange = (e: any, v: string) => {
    saveText(v);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    saveText(e.target.value);
  };

  return (
    <Autocomplete
      value={value}
      freeSolo
      autoHighlight
      autoSelect
      clearOnEscape
      disableClearable
      onChange={handleChange}
      options={captions[project] ? Object.keys(captions[project]) : []}
      size="small"
      sx={{ pl: 1, width: 155 }}
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: 18 }}
          value={value}
          sx={{ fontSize: 9, width: "100%" }}
          size="small"
          variant={"standard"}
          autoFocus
          onBlur={() => handleCaption()}
          onKeyDown={handleKey}
          onChange={handleTextChange}
        />
      )}
    />
  );
}

type CaptionButtonProps = {
  storyId: string;
  initialValue: string;
  onSelected?: (value?: string) => void;
  color?:
    | "inherit"
    | "action"
    | "disabled"
    | "error"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  icon?: any;
};

function CaptionButton({
  storyId,
  initialValue,
  onSelected,
  color,
  icon,
}: CaptionButtonProps) {
  const [input, setInput] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSelected = (value?: string) => {
    setInput(false);
    if (value !== undefined) {
      setValue(value);
    }
  };

  return (
    <Box>
      {!input && (
        <>
          {!value && (
            <IconButton
              size="small"
              onClick={() => {
                setInput(true);
              }}
            >
              {icon ? (
                icon
              ) : (
                <MoreHorizIcon color={color ? color : "disabled"} />
              )}
            </IconButton>
          )}

          <Box
            onClick={() => {
              setInput(true);
            }}
          >
            {value && (
              <Chip
                size="small"
                disabled={false}
                sx={{
                  background: "#c0d1dc",
                  width: "150px",
                  alignItems: "center",
                }}
                label={value}
              />
            )}
          </Box>
        </>
      )}
      {input && (
        <CaptionButtonInput
          storyId={storyId}
          initialValue={value}
          onSelected={handleSelected}
        />
      )}
    </Box>
  );
}

export function StoryCaption({
  item,
  initialValue,
}: {
  item: { SK: string };
  initialValue: string;
}) {
  return <CaptionButton initialValue={initialValue} storyId={item.SK} />;
}
