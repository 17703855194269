import {
  Fragment,
  SetStateAction,
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
} from "react";

import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";

import { captionsState, projectPickerState } from "./JotaiAtoms";

import { rebuildMessageText } from "./storydat";

import {
  SendMessageBox,
  unfinishedMessageFromCache,
  NotifyMessage,
} from "./SendMessageBox";
import { StoryItem } from "./StoryItem";
import { AutocompleteStory } from "./AutocompleteStory";
import { Thread } from "./Thread";
import store from "store2";

import * as u from "./utility";
import log from "./logger";
import { Icon } from "@iconify/react";
import { useAtomValue } from "jotai";
import { useHttpPost } from "./hooks";
import { MessagePreview } from "./MessagePreview";
import { TimelineChips, StoryChip } from "./TimeLineChips";
import { sortMessages } from "./SendMessageBox";
import { MessageItem } from "./MessageItem";

type CommentSectionProps = {
  onReload: () => void;
  handleSort: () => void;
  sortingOrder: boolean;
  setMessages: (value: React.SetStateAction<MessageItem[]>) => void;
  onMessageSent: (messsage?: NotifyMessage) => void;
  droppedFiles?: File[] | FileList;
  handleUploadedFileRemoved: () => void;
  item: StoryItem;
  bottomInputPresent: boolean;
  children: any;
};

export function CommentSection({
  onReload,
  handleSort,
  sortingOrder,
  setMessages,
  onMessageSent,
  droppedFiles,
  handleUploadedFileRemoved,
  item,
  bottomInputPresent,
  children,
}: CommentSectionProps) {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [message, setMessage] = useState<string>(
    unfinishedMessageFromCache(item)
  );
  const [password, setPassword] = useState<string>("");
  const [previousMessage, setPreviousMessage] = useState<string>("");
  const [focused, setFocused] = useState<number>(0);
  const [initialFocus, setInitialFocus] = useState<number>(1);
  const [bottomInputMounted, setBottomInputMounted] = useState<boolean>(false);
  const refFocus = useRef(0);

  const handleMessageSent = (msg?: NotifyMessage) => {
    if (msg && msg.text) {
      setPreviousMessage(msg.text);
    }
    onMessageSent(msg);
  };

  const handleFocusBlurEvents = (delta: number) => {
    refFocus.current += delta;
    setTimeout(() => {
      setFocused(refFocus.current);
    }, 400);
  };

  const handleFocus1 = () => {
    handleFocusBlurEvents(1);
  };

  const handleFocus2 = () => {
    handleFocusBlurEvents(1);
  };

  const handleBlur1 = () => {
    handleFocusBlurEvents(-1);
  };

  const handleBlur2 = () => {
    handleFocusBlurEvents(-1);
  };

  useEffect(() => {
    if (focused > 0) {
      setInitialFocus(0);
    }
  }, [focused]);

  // [1237] fix jitteriness in the ui when auto focusing on
  // the input whne there are multiple inputs in the threads
  // and stories a being loaded progressively
  useEffect(() => {
    if (bottomInputPresent) {
      setTimeout(() => {
        setBottomInputMounted(true);
      }, 3000);
    } else {
      setBottomInputMounted(false);
    }
  }, [bottomInputPresent]);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <p />
        <Stack direction="row" gap={1} alignItems={"flex-start"}>
          <Stack direction="row" gap={1} sx={{ mt: 2 }}>
            <Box>
              <IconButton size="small" disabled={false} onClick={onReload}>
                <ReplayIcon fontSize="inherit" />
              </IconButton>
            </Box>
            <Box>
              <Tooltip
                title={
                  sortingOrder
                    ? "Set newest at the top"
                    : "Set oldest at the top"
                }
              >
                <IconButton size="small" disabled={false} onClick={handleSort}>
                  <Icon
                    icon={sortingOrder ? "fa:sort-desc" : "fa:sort-asc"}
                    width={18}
                    height={18}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          <SendMessageBox
            item={item}
            setMessages={setMessages}
            id="top"
            onSent={handleMessageSent}
            droppedFiles={droppedFiles}
            onFileRemoved={handleUploadedFileRemoved}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            message={message}
            setMessage={setMessage}
            password={password}
            setPassword={setPassword}
            previousMessage={previousMessage}
            onBlur={handleBlur1}
            onFocus={handleFocus1}
            focused={focused + initialFocus}
          />
        </Stack>
        <p />
      </Box>

      {children}

      {bottomInputMounted && (
        <Box>
          <p />
          <Stack direction="row" gap={1}>
            <SendMessageBox
              item={item}
              setMessages={setMessages}
              id="bottom"
              onSent={handleMessageSent}
              droppedFiles={droppedFiles}
              onFileRemoved={handleUploadedFileRemoved}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              message={message}
              setMessage={setMessage}
              password={password}
              setPassword={setPassword}
              previousMessage={previousMessage}
              onBlur={handleBlur2}
              onFocus={handleFocus2}
              focused={focused + initialFocus}
            />
          </Stack>
        </Box>
      )}
    </>
  );
}

type StoryDetailsProps = {
  item: StoryItem;
  messages?: MessageItem[];
  setMessages: (value: React.SetStateAction<MessageItem[]>) => void;
  connected?: StoryItem[];
  setConnected: (value: React.SetStateAction<StoryItem[]>) => void;
  onNewMessage: (msg?: NotifyMessage) => void;
  onRequestReload: () => void;
};

export function StoryDetails({
  item,
  messages,
  setMessages,
  connected,
  setConnected,
  onNewMessage,
  onRequestReload,
}: StoryDetailsProps) {
  const [sortingOrder, setSortingOrder] = useState(
    item ? store.get(`SortingOrder:${item.PK}:${item.SK}`, false) : false
  );
  const captions = useAtomValue(captionsState);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [droppedFiles, setDroppedFiles] = useState<FileList | File[]>();
  const projectName = useAtomValue(projectPickerState);
  const httpPost = useHttpPost();

  const getData = async () => {
    try {
      const response = await httpPost("stories", {
        connected: true,
        story: item.SK,
      });
      setConnected(response.data.connected);
    } catch (err) {
      log.debug(err);
    } finally {
    }
  };

  const handleSort = () => {
    setSortingOrder(!sortingOrder);
    store(`SortingOrder:${item.PK}:${item.SK}`, !sortingOrder);
  };

  const getStoryCaption = (item: StoryItem) => {
    if (item.st_cap) {
      return item.st_cap;
    }

    const text = rebuildMessageText(item);
    if (text && text.length) {
      return text;
    }

    if (item.SK4) {
      return u.getStoryKey({ SK4: item.SK4 });
    }

    return item.SK;
  };

  const handleDelete = (other: StoryItem) => {
    if (!connected) {
      return;
    }

    const request = async () => {
      try {
        httpPost("label", {
          disconnect: {
            stories: [other.SK, item.SK],
          },
        });
      } catch (err) {}
    };
    request();

    let newValues = [];
    for (let v of connected) {
      if (v.SK !== other.SK) {
        newValues.push(v);
      }
    }
    setConnected(newValues);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, item: StoryItem) => {
    const url = u.getStoryUrl(item, projectName);

    if (e.metaKey) {
      e.stopPropagation();
      window.open(url, "_blank", "noreferrer");
    } else {
      window.location.href = url;
    }
  };

  const handleConnect = (value: string) => {
    const request = async (story: string) => {
      try {
        await httpPost("label", {
          connect: {
            stories: [story, item.SK],
          },
        });
        getData();
      } catch (err) {}
    };

    if (captions[projectName] && captions[projectName][value]) {
      request(captions[projectName][value]);
      return;
    }

    let story: string | undefined = value;
    try {
      const segments = new URL(value).pathname.split("/");
      const last = segments.pop() || segments.pop(); // Handle potential trailing slash
      story = last;
    } catch (e) {}

    if (story) {
      if (story.startsWith("STORY-")) {
        story = story.replace("STORY-", "");
      }
      request(story);
    }
  };

  const handleMessageSent = (msg?: NotifyMessage) => {
    setDroppedFiles(undefined);
    if (onNewMessage) {
      onNewMessage(msg);
    }
  };

  const handleReload = () => {
    if (onRequestReload) {
      onRequestReload();
    }
  };

  const countVisibleMessage = (messages: MessageItem[]) => {
    let count = 0;
    if (messages) {
      for (let m of messages) {
        if (!m.st_hide) {
          count += 1;
        }
      }
    }
    return count;
  };

  // const setMessagesOriginalOrder = (func: (messages: MessageItem[]) => MessageItem[]) => {
  //   setMessages((prev: MessageItem[]) => {
  //     const sorted = sortMessages(func(prev), !sortingOrder);
  //     return sorted;
  //   });
  // };
  const setMessagesOriginalOrder = (value: SetStateAction<MessageItem[]>) => {
    setMessages((prev: MessageItem[]) => {
      const unordered = typeof value === "function" ? value(prev) : value;
      const sorted = sortMessages(unordered, !sortingOrder);
      return sorted;
    });
  };

  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    setDroppedFiles((prev: FileList | undefined | File[]) => {
      if (!prev) {
        return e.dataTransfer.files;
      } else {
        return [...prev, ...e.dataTransfer.files];
      }
    });
  };

  const handleUploadedFileRemoved = useCallback(() => {
    setDroppedFiles(undefined);
  }, [setDroppedFiles]);

  return (
    <Stack
      direction="row"
      alignItems={"flex-start"}
      justifyContent="space-between"
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      sx={{
        background: dragActive ? "lightgrey" : "none",
        borderRadius: "4px",
      }}
    >
      <Box width="100%" sx={{ p: 0, m: 0, pb: 1 }}>
        <Divider sx={{ mt: 0.1, mb: 0.2 }} />
        <Stack gap={0.3} direction="row" alignItems={"center"} sx={{ m: 0 }}>
          <Box alignContent={"center"} sx={{ whiteSpace: "nowrap", ml: 5 }}>
            <Typography fontSize={9} color="silver" fontWeight={"bold"}>
              related stories:
            </Typography>
          </Box>
          <Grid container maxWidth="100%" spacing={0.1}>
            {connected &&
              connected.map((item: StoryItem) => (
                <Grid item key={item.SK}>
                  <Tooltip
                    key={item.SK}
                    title={
                      <Fragment>
                        <StoryFastPreview
                          item={item}
                          fontSize={undefined}
                          sx={undefined}
                        />
                      </Fragment>
                    }
                  >
                    <Chip
                      sx={{ maxWidth: "150px" }}
                      size="small"
                      label={getStoryCaption(item)}
                      onDelete={() => handleDelete(item)}
                      onClick={(e) => handleClick(e, item)}
                    />
                  </Tooltip>
                </Grid>
              ))}
            <Grid item key={u.getCurrentTimestamp()} sx={{ width: "20%" }}>
              <AutocompleteStory
                variant="standard"
                onSelected={handleConnect}
                sx={{ minWidth: "100" }}
                placeholderText={undefined}
                onInput={undefined}
                disabled={undefined}
                onTextInput={undefined}
              >
                <AddCircleIcon color={"disabled"} fontSize="inherit" />
              </AutocompleteStory>
            </Grid>
          </Grid>
        </Stack>

        <CommentSection
          onReload={handleReload}
          handleSort={handleSort}
          sortingOrder={sortingOrder}
          setMessages={setMessages}
          droppedFiles={droppedFiles}
          item={item}
          handleUploadedFileRemoved={handleUploadedFileRemoved}
          onMessageSent={handleMessageSent}
          bottomInputPresent={
            messages && countVisibleMessage(messages) > 15 ? true : false
          }
        >
          <>
            {messages && messages.length > 1 && (
              <Stack direction={"row"}>
                <Box sx={{ width: "50px" }}></Box>
                <MemoThread
                  messages={sortMessages(messages, sortingOrder)}
                  setMessages={setMessagesOriginalOrder}
                  allowReplay={false}
                  story={item}
                  background={dragActive ? "lightgray" : "white"}
                  overflow={false}
                  expand={undefined}
                />
              </Stack>
            )}
          </>
        </CommentSection>
      </Box>
    </Stack>
  );
}

type StoryFastPreviewProps = {
  item?: StoryItem;
  fontSize?: number;
  sx?: any;
};

export function StoryFastPreview({
  item,
  fontSize,
  sx,
}: StoryFastPreviewProps) {
  if (!item) {
    return null;
  }

  return (
    <Stack direction="column" sx={{ pb: 0.4 }}>
      <Stack direction="row" sx={{ mb: 1, mt: 1 }}>
        {item && item.SK4 && <StoryChip item={item} rowSelected={undefined} />}
        <TimelineChips item={item} />
      </Stack>
      {/* <StoryCell item={story} expand={false} hideControls={true} /> */}
      {item.st_cap && (
        <Box sx={{}}>
          <Chip
            size="small"
            disabled={false}
            sx={{
              background: "#c0d1dc",
              alignItems: "center",
              mb: 0.5,
            }}
            label={item.st_cap}
          />
        </Box>
      )}
      <Box>
        <MessagePreview
          item={item}
          fontSize={fontSize || 11}
          readOnly
          sx={sx || { "&.MuiLink-root": { color: "lightskyblue" } }}
          storyKey={undefined}
          overflow={undefined}
        />
      </Box>
    </Stack>
  );
}

export function DarkStoryPreview(props: any) {
  return (
    <Box
      sx={{
        // backgroundColor: "lightgrey",
        p: 2,
        borderRadius: "7px",
        backgroundColor: "#606060",
        color: "white",
      }}
    >
      <StoryFastPreview {...props} />
    </Box>
  );
}

const MemoThread = memo(Thread);
