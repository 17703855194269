import { useState, useEffect, useRef } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { useHttpPost } from "./hooks";
import * as lodash from "lodash";

import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import * as u from "./utility";
import { DEFAULT_FONT_SIZE } from "./constants";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export type MeddledFile = File & {
  detected_mimetype?: string;
  uploaded_file?: File;
  index?: number;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function getLastOpenedPage(item: { st_ui?: any }, index: number) {
  return u.getUiProp(item, "page", index, 1);
}

type PdfViewProps = {
  numPages: number;
  file: { index?: number };
  item: { PK: string; SK: string; st_ui?: any };
  fontSize: number;
};

function PdfView({ numPages, file, item, fontSize }: PdfViewProps) {
  const initialFactor: number =
    file.index === undefined ? 1 : u.getImageSizeFactor(item, file.index);
  const initialPage: number = lodash.clamp(
    file.index === undefined ? 1 : getLastOpenedPage(item, file.index),
    1,
    numPages
  );

  const [pageNumber, setPageNumber] = useState<number>(initialPage);
  const [hover, setHover] = useState<boolean>(false);
  const [sizeFactor, setSizeFactor] = useState<number>(initialFactor);
  const [commitedFactor, setCommitedFactor] = useState<number>(initialFactor);
  const [commitedPage, setCommitedPage] = useState<number>(initialPage);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number>();
  const [maxWidth, setMaxWidth] = useState<number>(100);
  const ref = useRef<HTMLElement>();
  const httpPost = useHttpPost();

  const defaultWidth = 350;
  const isLoading = renderedPageNumber !== pageNumber;

  function changePage(offset: number) {
    setPageNumber((prev: number) => lodash.clamp(prev + offset, 1, numPages));
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const handleSizeDown = () => {
    setSizeFactor((prev: number) => prev / 1.3);
  };

  const handleSizeUp = () => {
    setSizeFactor((prev: number) => prev * 1.3);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);

    const request = async (props: any) => {
      try {
        httpPost("label", {
          setmessageprops: {
            PK: item.PK,
            SK: item.SK,
            ...props,
          },
        });
      } catch (err) {}
    };

    // dynamodb does not support floats, so we work around that limitation
    if (Math.abs(sizeFactor - commitedFactor) > 0.01) {
      const commit = Math.round(sizeFactor * 100);
      setCommitedFactor(commit / 100);
      if (file.index !== undefined) {
        let props: any = {};
        props[`st_ui/f${file.index}`] = commit;
        request(props);
      }
    }

    if (pageNumber !== commitedPage) {
      setCommitedPage(pageNumber);

      if (file.index !== undefined) {
        let props: any = {};
        props[`st_ui/page/${file.index}`] = pageNumber;
        request(props);
      }
    }
  };

  const getPageWidth = (): number => {
    return lodash.clamp(
      defaultWidth * sizeFactor * (fontSize / DEFAULT_FONT_SIZE),
      50,
      maxWidth
    );
  };

  useEffect(() => {
    if (ref.current) {
      setMaxWidth(ref.current.getBoundingClientRect().width);
      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        // 0.9 is here to disallow the pdf element grow beyond it's parent causing
        // the callback to be fired again and maxWidth updating as a result ad infinitum
        // like a recursion, pretty much
        const width = Math.floor(
          entries[0].target.getBoundingClientRect().width * 1
        );
        setMaxWidth(width);
      });
      observer.observe(ref.current);
    }
  }, []);

  return (
    <>
      {numPages > 0 && (
        <Box
          ref={ref}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{ /*background: "black",*/ width: "100%", maxWidth: "100%" }}
        >
          <Box sx={{ position: "relative", display: "inline-block" }}>
            {item && hover && (
              <>
                <Box sx={{ position: "absolute", left: 0, zIndex: 5 }}>
                  <Stack spacing={0} direction="row">
                    <IconButton onClick={handleSizeUp}>
                      <AddCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton onClick={handleSizeDown}>
                      <RemoveCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 5,
                    width: "100%",
                  }}
                >
                  <Stack
                    spacing={0}
                    direction={"row"}
                    justifyContent={"center"}
                  >
                    <IconButton
                      onClick={previousPage}
                      disabled={pageNumber === 1}
                    >
                      <ArrowLeftIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton
                      onClick={nextPage}
                      disabled={pageNumber === numPages}
                    >
                      <ArrowRightIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            )}
            {isLoading && renderedPageNumber && (
              <Page
                key={renderedPageNumber}
                pageNumber={renderedPageNumber}
                width={getPageWidth()}
              ></Page>
            )}
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              width={getPageWidth()}
              onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
              className={`${isLoading ? "loadingPage" : ""}`}
            ></Page>
          </Box>
        </Box>
      )}
    </>
  );
}

type PdfProps = {
  data?: string;
  file?: File | MeddledFile;
  item?: any;
  onError?: (error: Error) => void;
  fontSize?: number;
};

export function Pdf({ data, file, item, onError, fontSize }: PdfProps) {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess(pdf: any) {
    setNumPages(pdf.numPages);
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.metaKey) {
      e.stopPropagation();
      if (u.detectBrowser() === "chrome") {
        let wnd = window.open("", "_blank");
        if (!wnd) {
          return;
        }

        wnd.document.open();
        wnd.document.write(
          `<iframe width="100%" height="100%" src="data:application/pdf;base64,${data}"/>`
        );
        wnd.document.close();
      } else {
        window.open(
          `data:application/pdf;base64,${data}`,
          "_blank",
          "noreferrer"
        );
      }
    }
  };

  useEffect(() => {}, [data]);

  const getPdfData = () => {
    if (data) {
      // file was downloaded from d3
      return `data:application/pdf;base64,${data}`;
    } else if ((file as MeddledFile).uploaded_file) {
      // file has been just uploaded
      return (file as MeddledFile).uploaded_file;
    }

    return file; // uploaded file from preview
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Document
        file={getPdfData()}
        onLoadSuccess={onDocumentLoadSuccess}
        onClick={handleClick}
        onLoadError={onError}
        onSourceError={onError}
        loading={""}
      >
        {numPages > 0 && (
          <PdfView
            numPages={numPages}
            file={file as MeddledFile}
            item={item}
            fontSize={fontSize || DEFAULT_FONT_SIZE}
          />
        )}
      </Document>
    </Box>
  );
}
