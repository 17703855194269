import { useState, useRef } from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField, { TextFieldVariants } from "@mui/material/TextField";
import { SuggestionsPopper } from "./SendMessageBox";

import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import * as lodash from "lodash";

import { useProjectCaptions } from "./hooks";

type AutocompleteStoryInputProps = {
  onSelected: (value: string) => void;
  onHighlight?: (value: string) => void;
  variant: TextFieldVariants;
  placeholderText?: string;
  sx?: any;
  options: { [key: string]: string }; // Map<string, string>
};

export function AutocompleteStoryInput({
  onSelected,
  onHighlight,
  variant,
  placeholderText,
  sx,
  options,
}: AutocompleteStoryInputProps) {
  const [value, setValue] = useState<string>("");
  const inputRef = useRef();
  const suggestionsRef = useRef<any[]>();
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selected - 1);
      setSelected(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(suggestionsRef.current.length - 1, selected + 1);
      setSelected(index);
    }
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setOpen(true);
    if (e.key === "Enter") {
      handleSelected(value);
    } else if (e.key === "Escape") {
      setValue("");
    } else if (e.key === "Tab") {
      e.preventDefault();

      let suggestion = value;
      if (suggestionsRef.current && suggestionsRef.current.length > 0) {
        const index = lodash.clamp(
          selected,
          0,
          suggestionsRef.current.length - 1
        );
        suggestion = suggestionsRef.current[index].text;
      }

      onSelected(suggestion);
      setValue("");
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
    }
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setSelected(0);
  };

  const handleSelected = (value: string) => {
    onSelected(value);
    setValue("");
  };

  const handleBlur = () => {
    // give a chance to downstream event to handle
    // like allow the label to be removed from StoryLabels when working in
    // minimized form

    setTimeout(() => {
      setValue("");
      setOpen(false);
    }, 300);
  };

  return (
    <Box sx={{ maxWidth: "150px", width: "100%" }}>
      <TextField
        sx={{ fontSize: 9, width: "100%", ...(sx ? sx : {}) }}
        size="small"
        variant={variant}
        autoFocus
        placeholder={placeholderText}
        onChange={handleTextInput}
        value={value}
        onBlur={handleBlur}
        onKeyDown={handleKey}
        inputRef={inputRef}
        InputProps={{
          style: { fontSize: 12 },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                component="span"
                size="small"
                onClick={() => handleSelected(value)}
                disabled={value.trim().length === 0}
              >
                <SearchIcon fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {open && value && inputRef && inputRef.current && (
        <SuggestionsPopper
          anchorRef={inputRef}
          buffer={{ text: value }}
          suggestionsRef={suggestionsRef}
          onSelected={handleSelected}
          selectedIndex={selected}
          onHighlight={onHighlight}
          options={options}
        />
      )}
    </Box>
  );
}

type AutocompleteStoryProps = {
  onSelected: (value: string) => void;
  variant: TextFieldVariants;
  children: any;
  placeholderText?: string;
  onInput?: (state: boolean) => void;
  disabled?: boolean;
  onTextInput?: (value: string) => void;
  sx: any;
};

export function AutocompleteStory({
  onSelected,
  variant,
  children,
  placeholderText,
  onInput,
  disabled,
  onTextInput,
  sx,
}: AutocompleteStoryProps) {
  const [input, setInput] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>();
  const suggestionsRef = useRef<any[]>();
  const [selected, setSelected] = useState<number>(0);
  const captions = useProjectCaptions();

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selected - 1);
      setSelected(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(suggestionsRef.current.length - 1, selected + 1);
      setSelected(index);
    }
  };

  const changeInput = (state: boolean) => {
    setInput(state);
    if (onInput) {
      onInput(state);
    }
  };

  const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSelected((e.target as any).value);
      changeInput(false);
    } else if (e.key === "Escape") {
      changeInput(false);
      setValue("");
    } else if (e.key === "Tab") {
      e.preventDefault();

      let suggestion: string = (e.target as any).value;
      if (suggestionsRef.current && suggestionsRef.current.length) {
        const index = lodash.clamp(
          selected,
          0,
          suggestionsRef.current.length - 1
        );
        suggestion = suggestionsRef.current[index].text;
      }

      onSelected(suggestion);
      changeInput(false);
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
    }
  };

  // const handleChange = (e, val) => {
  //   if (e.type === "click") {
  //     onSelected(val, e);
  //   } else if (e.type === "blur" && ref.current && ref.current.key === "Tab") {
  //     onSelected(val, ref.current);
  //   }
  //   changeInput(false);
  // };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onTextInput) {
      onTextInput(e.target.value);
    }
    setValue(e.target.value);
    setSelected(0);
  };

  const handleSuggestionSelected = (value: string) => {
    setValue(value);
    onSelected(value);
    changeInput(false);
  };

  const handleBlur = () => {
    // give a chance to downstream event to handle
    // like allow the label to be removed from StoryLabels when working in
    // minimized form
    setTimeout(() => {
      changeInput(false);
      setValue("");
    }, 300);
  };

  return (
    <>
      {!input && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              changeInput(true);
            }}
            disabled={disabled}
          >
            {children}
          </IconButton>
        </Box>
      )}
      {input && (
        <Box sx={{ maxWidth: "150px", width: "100%" }}>
          <TextField
            sx={{ fontSize: 9, width: "100%", ...(sx ? sx : {}) }}
            size="small"
            variant={variant}
            autoFocus
            placeholder={placeholderText}
            onChange={handleTextInput}
            value={value}
            onBlur={handleBlur}
            onKeyDown={handleKey}
            inputRef={inputRef}
          />
          {value && inputRef && inputRef.current && (
            <SuggestionsPopper
              anchorRef={inputRef}
              buffer={{ text: value }}
              suggestionsRef={suggestionsRef}
              onSelected={handleSuggestionSelected}
              selectedIndex={selected}
              options={captions}
            />
          )}
        </Box>
      )}
    </>
  );
}
