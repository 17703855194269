export const DEFAULT_FONT_SIZE = 13;
export const MIN_FONT_SIZE = 5;
export const MAX_FONT_SIZE = 21;
export const FONT_SIZE = [DEFAULT_FONT_SIZE, MIN_FONT_SIZE, MAX_FONT_SIZE];
export const ICON_FONT_SIZE = 15;
export const MAX_FILE_SIZE_PREVIEW = 9 * 1024 * 1024;

// user configs, see admin.py
export const GITHUB_WHITELIST = "github_whitelist";
export const GITLAB_WHITELIST = "gitlab_whitelist";
export const SLACK_WHITELIST = "slack_whitelist";
export const WHITELISTS = [GITHUB_WHITELIST, GITLAB_WHITELIST, SLACK_WHITELIST];

export const GITHUB_BLACKLIST = "github_blacklist";
export const GITLAB_BLACKLIST = "gitlab_blacklist";
export const SLACK_BLACKLIST = "slack_blacklist";
export const BLACKLISTS = [GITHUB_BLACKLIST, GITLAB_BLACKLIST, SLACK_BLACKLIST];

export const SLACK_VERIFICATION_TOKEN = "slack_verification_token";
export const GITHUB_SECRET = "github_secret";
export const GITLAB_SECRET = "gitlab_secret";
export const WEBHOOK_SECRETS = [
  SLACK_VERIFICATION_TOKEN,
  GITHUB_SECRET,
  GITLAB_SECRET,
];

export const SLACK_API_TOKEN = "slack_api_token";
export const GITLAB_API_TOKEN = "gitlab_api_token";
export const GITHUB_API_TOKEN = "github_api_token";
export const API_TOKENS = [SLACK_API_TOKEN, GITHUB_API_TOKEN, GITLAB_API_TOKEN];

export const USER_CONFIGS = [
  ...WHITELISTS,
  ...BLACKLISTS,
  ...API_TOKENS,
  ...WEBHOOK_SECRETS,
];
