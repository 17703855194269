import "./App.css";

import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/Warning";

import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { MuiChipsInput } from "mui-chips-input";

import * as riki from "jsriki";

import { activeLabelsState } from "./JotaiAtoms";
import { reloadState } from "./JotaiAtoms";

import { useAtom, useAtomValue } from "jotai";

import log from "./logger";

import * as u from "./utility";

import { DEFAULT_FONT_SIZE } from "./constants";
import { useHttpPostAndProject, useHookUrl } from "./hooks";
import { USER_CONFIGS } from "./constants";

function DangerousZone() {
  const [confirmation, setConfirmation] = useState("");
  const [statement, setStatement] = useState("");
  const [httpPost, project] = useHttpPostAndProject();

  useEffect(() => {
    if (project) {
      setStatement(`permanently delete ${project}`);
    } else {
      setStatement("");
    }
  }, [project]);

  const handleDelete = () => {
    const request = async () => {
      try {
        await httpPost("admin", { action: "revoke" });
        window.location.href = u.getHomeUrl();
      } catch (err) {}
    };

    if (statement && confirmation === statement) {
      setConfirmation("");
      request();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmation(e.target.value);
  };

  return (
    <Box
      border={1}
      sx={{ p: 2, mt: 2, backgroundColor: "#ffefef" }}
      color="red"
      borderRadius={3}
    >
      <Typography color="red" fontWeight={"bold"} fontSize={14}>
        Dangerous stuff. Tread carefully.
      </Typography>
      <Stack direction={"row"} gap={1} sx={{ mt: 2 }}>
        <TextField
          label={statement}
          value={confirmation}
          onChange={handleChange}
        />
        <Button onClick={handleDelete} disabled={confirmation !== statement}>
          Delete project
        </Button>
      </Stack>
    </Box>
  );
}

function TeamMembers() {
  const [chips, setChips] = useState<string[]>();
  const reloadData = useAtomValue(reloadState);
  const [httpPost, project] = useHttpPostAndProject();

  const getData = () => {
    const request = async () => {
      try {
        const response = await httpPost("admin", {
          action: "users",
        });
        setChips(response.data.users);
      } catch (err) {
        log.debug(err);
      }
    };
    request();
  };

  useEffect(() => {
    if (!chips && project) {
      getData();
    }
  });

  useEffect(() => {
    if (project) {
      getData();
    }
  }, [project, reloadData]);

  const handleChange = (newChips: string[]) => {
    const request = async (action: string, email: string) => {
      try {
        httpPost("admin", { action: action, email: email });
      } catch (err) {}
    };
    for (let email of newChips) {
      if (!chips || !chips.includes(email)) {
        request("grant", email);
      }
    }

    if (chips) {
      for (let email of chips) {
        if (!newChips.includes(email)) {
          request("revoke", email);
        }
      }
    }
    setChips(newChips);
  };

  return (
    <Box border={1} sx={{ p: 2 }} borderRadius={3}>
      <Typography
        fontWeight={"bold"}
        sx={{ mb: 2 }}
        fontSize={DEFAULT_FONT_SIZE}
      >
        Manage your team, invite or remove people. Enter an email.
      </Typography>
      <MuiChipsInput value={chips || []} onChange={handleChange} />
    </Box>
  );
}

type KeyValueConfigProps = {
  items: { name: string; value: string; editable?: boolean; warn?: string }[];
  onCreate: (name: string, value: string) => void;
  onEdit: (name: string, value: string, index: number) => void;
  onDelete?: (index: number) => void;
};

function KeyValueConfig({
  items,
  onCreate,
  onEdit,
  onDelete,
}: KeyValueConfigProps) {
  const [generation, setGeneration] = useState(0);

  const handleDelete = (index: number) => {
    setGeneration((prev) => prev + 1);
    if (onDelete) {
      onDelete(index);
    }
  };

  const isDuplicateOrEmpty = (key: string, index?: number) => {
    if (!key) {
      return true;
    }

    for (let i = 0; i < items.length; ++i) {
      if (i !== index && key === items[i].name) {
        return true;
      }
    }

    return false;
  };

  const handleEdit = (key: string, value: string, index: number) => {
    if (isDuplicateOrEmpty(key, index)) {
      return false;
    }
    const allowed = onEdit(key, value, index);
    if (allowed === undefined || allowed) {
      return true;
    }

    return false;
  };

  const handleCreate = (key: string, value: string) => {
    if (isDuplicateOrEmpty(key)) {
      return false;
    }
    onCreate(key, value);
    return true;
  };

  return (
    <TableContainer component={Box}>
      <Table sx={{}} aria-label="simple table" size="small">
        <TableBody>
          {items &&
            items.map((row, index) => (
              <ConfigLine
                key={`${generation}:${index}`}
                initKey={row.name}
                initValue={row.value}
                editable={row.editable === undefined || row.editable}
                onEdit={(k, v) => handleEdit(k, v, index)}
                onDelete={onDelete ? () => handleDelete(index) : undefined}
                warn={row.warn}
              />
            ))}
          {items && (
            <ConfigLine
              key={items.length}
              initKey=""
              initValue=""
              editable={true}
              onEdit={handleCreate}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ProjectConfig() {
  const reloadData = useAtomValue(reloadState);
  const [config, setConfig] = useState<
    { name: string; value: string; editable?: boolean; warn?: string }[]
  >([]);
  const [httpPost, project] = useHttpPostAndProject();

  useEffect(() => {
    if (!config && project) {
      getData();
    }
  });

  useEffect(() => {
    if (project) {
      getData();
    }
  }, [reloadData, project]);

  const handleEdit = (key: string, value: string, index?: number) => {
    const request = async () => {
      try {
        httpPost("admin", { action: "config", key: key, value: value });
      } catch (err) {
      } finally {
      }
    };
    request();

    if (index !== undefined) {
      let newConfig = [...config];
      const validName = USER_CONFIGS.includes(key);
      newConfig[index] = {
        name: key,
        value: value,
        warn: !validName ? "Invalid name" : undefined,
      };
      setConfig(newConfig);
    }
  };

  const handleNewConfig = (key: string, value: string) => {
    const validName = USER_CONFIGS.includes(key);
    setConfig([
      ...config,
      {
        name: key,
        value: value,
        warn: !validName ? "Invalid name" : undefined,
      },
    ]);
    if (key && value) {
      handleEdit(key, value);
    }
  };

  const handleDelete = (index: number) => {
    const request = async () => {
      try {
        httpPost("admin", {
          action: "config",
          key: config[index].name,
          value: "",
        });
      } catch (err) {
      } finally {
      }
    };
    request();

    let newConfig = config.slice(0, index).concat(config.slice(index + 1));
    setConfig(newConfig);
  };

  const getData = () => {
    const request = async () => {
      try {
        const response = await httpPost("admin", {
          action: "readconfig",
        });
        let conf = [];
        for (let [k, v] of Object.entries(response.data)) {
          if (k !== "SK" && k !== "PK" && k !== "original_owner") {
            conf.push({
              name: k,
              value: v as string,
              editable: k !== "owner" && k !== "project",
            });
          }
        }
        const order = ["owner", "slack", "github", "gitlab"];
        let reordered: { name: string; value: string; editable?: boolean }[] =
          [];
        let visited = new Set();
        for (let o of order) {
          for (let c of conf) {
            if (c.name.startsWith(o)) {
              reordered.push(c);
              visited.add(c.name);
            }
          }
        }
        for (let c of conf) {
          if (!visited.has(c.name)) {
            reordered.push(c);
          }
        }

        setConfig(reordered);
      } catch (err) {
        log.debug(err);
      } finally {
      }
    };
    request();
  };

  return (
    <Stack direction={"column"}>
      <Typography fontWeight={"bold"} sx={{ m: 2 }} fontSize={14}>
        Project key-value settings:
      </Typography>
      <KeyValueConfig
        items={config}
        onCreate={handleNewConfig}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </Stack>
  );
}

function Workflow() {
  const [workflow, setWorkflow] = useState<string>();
  const [error, setError] = useState(false);
  const activeLabels = useAtomValue(activeLabelsState);
  const [dirty, setDirty] = useState(false);
  const [focused, setFocused] = useState(false);
  const [httpPost, project] = useHttpPostAndProject();

  const getData = () => {
    const request = async () => {
      try {
        const response = await httpPost("admin", {
          action: "getworkflow",
        });
        setWorkflow(JSON.stringify(response.data.workflow, null, 2));
        setError(false);
      } catch (err) {
        log.debug(err);
      }
    };
    request();
  };

  useEffect(() => {
    if (!workflow) {
      getData();
    }
  });

  useEffect(() => {
    getData();
  }, [project]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflow(e.target.value || " ");
    setDirty(true);
  };

  const handleSave = () => {
    const request = async (workflow: any) => {
      try {
        httpPost("admin", {
          action: "setworkflow",
          workflow: workflow,
        });
      } catch (err) {}
    };

    try {
      let parsed: { states: { [key: string]: string[] } } =
        JSON.parse('{"states":{}}');
      if (workflow && workflow.trim()) {
        parsed = JSON.parse(workflow);
      }

      let display: { [key: string]: string } = {};
      if (activeLabels) {
        for (let [k, v] of Object.entries(activeLabels)) {
          const d = v.display;
          if (typeof d === "string") {
            display[d] = k;
          }
        }
      }

      let fixedWorkflow: { states: { [key: string]: string[] } } = {
        states: {},
      };
      for (let [k, v] of Object.entries(parsed.states)) {
        let fixed: string[] = [];
        for (let label of v) {
          if (display[label] && (!activeLabels || !activeLabels[label])) {
            fixed.push(display[label]);
          } else {
            fixed.push(label);
          }
        }
        fixedWorkflow.states[k] = fixed;
      }

      setWorkflow(JSON.stringify(fixedWorkflow, null, 2));
      request(fixedWorkflow);
      setError(false);
      setDirty(false);
    } catch (e) {
      setError(true);
    }
  };

  const handleCancel = () => {
    getData();
    setDirty(false);
  };

  return (
    <Box border={0} sx={{ m: 0, p: 2 }}>
      <TextField
        error={error}
        fullWidth
        size="small"
        value={workflow || " "}
        multiline
        onChange={handleChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        variant="outlined"
        label="Default Workflow"
        inputProps={{
          style: {
            fontSize: 12,
            fontFamily: "monospace",
            lineHeight: 1.5,
            color: focused ? "black" : "#707070",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Stack direction={"row"}>
                <Button onClick={handleSave} disabled={!dirty}>
                  Save
                </Button>
                <Button onClick={handleCancel} disabled={!dirty}>
                  Cancel
                </Button>
              </Stack>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export function Settings() {
  const [denied, setDenied] = useState("unknown");
  const reloadData = useAtomValue(reloadState);
  const [httpPost, project] = useHttpPostAndProject();
  const hookUrl = useHookUrl();

  const getData = (project: string) => {
    const request = async () => {
      try {
        await httpPost("admin", { action: "users" });
        setDenied("allowed");
      } catch (err) {
        setDenied("denied");
      }
    };

    if (project) {
      request();
    }
  };

  useEffect(() => {
    if (denied === "unknown" && project) {
      getData(project);
    }
  });

  useEffect(() => {
    if (project) {
      getData(project);
    }
  }, [project, reloadData]);

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(hookUrl);
  };

  return (
    <>
      {denied === "allowed" && (
        <Stack>
          <Stack maxWidth={750} direction="column">
            <Box border={1} sx={{ mt: 2, mb: 2, p: 2 }} borderRadius={3}>
              <Typography fontSize={9}>Your webhook URL:</Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>{hookUrl}</Typography>
                <IconButton size="small" sx={{ pl: 1 }} onClick={handleCopyUrl}>
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Box>
            <Stack border={1} borderRadius={3} gap={5} sx={{ p: 2 }}>
              <ProjectConfig />
              <Workflow />
              <UserQueries />
            </Stack>
            <Box sx={{ m: 1 }}></Box>
            <TeamMembers />
            <DangerousZone />
          </Stack>
        </Stack>
      )}
    </>
  );
}

type ConfigLineProps = {
  initKey: string;
  initValue: string;
  onEdit: (name: string, value: string) => boolean;
  editable: boolean;
  onDelete?: () => void;
  warn?: string;
};

function ConfigLine({
  initKey,
  initValue,
  onEdit,
  editable,
  onDelete,
  warn,
}: ConfigLineProps) {
  const [editKey, setEditKey] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [key, setKey] = useState(initKey);
  const [value, setValue] = useState(initValue);
  const [focusValue, setFocusValue] = useState(true);

  const handleEditKey = () => {
    setEditKey(true);
    setEditValue(false);
  };

  const handleEditValue = () => {
    setEditValue(true);
    setEditKey(false);
  };

  const handleEdit = (
    e: React.KeyboardEvent<HTMLDivElement>,
    field: string
  ) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault();

      if (field === "key") {
        const currentKey = (e.target as any).value;
        setKey(currentKey);

        let editAllowed = currentKey.length > 0;
        if (currentKey !== initKey) {
          editAllowed = onEdit(currentKey, value);
        }

        if (editAllowed) {
          setEditKey(false);
          setFocusValue(true);
          setEditValue(true);
        }
      } else if (field === "value") {
        const currentValue = (e.target as any).value;
        if (onEdit(key, currentValue)) {
          setValue(currentValue);
          setFocusValue(false);
          setEditValue(false);
        }
      }
    } else if (e.key === "Escape") {
      if (field === "key") {
        setKey(initKey);
        setEditKey(false);
      } else {
        setValue(initValue);
        setEditValue(false);
      }
    }
  };

  const handleBlur = (e: any, field: string) => {
    if (field === "key") {
      const current = e.target.value;
      if (current && current !== initKey) {
        if (onEdit(current, value)) {
          setKey(current);
        } else {
          setKey(initKey);
        }
      }
      setEditKey(false);
    } else if (field === "value") {
      const current = e.target.value;
      if (current && current !== initValue) {
        if (onEdit(key, current)) {
          setValue(current);
        }
      }
      setEditValue(false);
    }
  };

  return (
    <TableRow
      key={"_add_new_project_configuration_"}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        width="25%"
        component="th"
        scope="row"
        onClick={editable ? handleEditKey : undefined}
      >
        {!editKey && (
          <Stack
            direction="row"
            gap={0.5}
            alignItems={"center"}
            // justifyContent={!initKey ? "" : "space-between"}
          >
            {warn && (
              <Tooltip title={warn}>
                <WarningIcon sx={{ fontSize: 17, color: "orange" }} />
              </Tooltip>
            )}

            <Typography fontSize={12}>{key}</Typography>

            {editable && (
              <IconButton size="small" onClick={handleEditKey}>
                <>
                  {(initKey || initValue) && (
                    <EditIcon sx={{ fontSize: 12, color: "silver" }} />
                  )}
                  {!initKey && !initValue && <AddIcon fontSize="inherit" />}
                </>
              </IconButton>
            )}
          </Stack>
        )}
        {editKey && (
          <TextField
            fullWidth
            autoFocus
            defaultValue={key}
            variant="standard"
            onKeyDown={(e) => {
              handleEdit(e, "key");
            }}
            onBlur={(e) => {
              handleBlur(e, "key");
            }}
            inputProps={{
              style: { fontSize: 12 },
            }}
          />
        )}
      </TableCell>
      <TableCell
        align="left"
        onClick={editable && key ? handleEditValue : undefined}
      >
        {!editValue && key && (
          <Stack
            direction="row"
            gap={0.5}
            alignItems={"center"}
            // justifyContent={"space-between"}
          >
            <Typography fontSize={12}>{value}</Typography>
            {editable && (
              <IconButton size="small" onClick={handleEditValue}>
                <EditIcon sx={{ fontSize: 12, color: "silver" }} />
              </IconButton>
            )}
          </Stack>
        )}
        {editValue && (
          <TextField
            fullWidth
            autoFocus
            defaultValue={value}
            variant="standard"
            focused={focusValue}
            onKeyDown={(e) => {
              handleEdit(e, "value");
            }}
            onBlur={(e) => {
              handleBlur(e, "value");
            }}
            inputProps={{
              style: { fontSize: 12 },
            }}
          />
        )}
      </TableCell>
      {onDelete && editable && (
        <TableCell align="right" width={"10%"}>
          <IconButton onClick={onDelete} size="small">
            <DeleteIcon fontSize={"inherit"} />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}

function UserQueries() {
  const [items, setItems] = useState<
    { name: string; value: string; warn?: string }[]
  >([]);
  const reloadData = useAtomValue(reloadState);
  const [activeLabels, setActiveLabels] = useAtom(activeLabelsState);
  const [httpPost, project] = useHttpPostAndProject();

  useEffect(() => {
    getData(project);
  }, [project, reloadData]);

  const isShadowedByRegularLabel = (name: string) => {
    return activeLabels && activeLabels[name] ? true : false;
  };

  const getData = (project: string) => {
    if (project) {
      const request = async () => {
        try {
          const result = await httpPost("stories", {
            activelabels: true,
          });

          const sorted = result.data.activelabels.queries.sort(
            (a: any, b: any) => Number(b.created) - Number(a.created)
          );
          const newItems = sorted.map((e: any) => {
            return { name: e.name, value: e.query };
          });
          setItems(newItems);
        } catch (err) {}
      };
      request();
    }
  };

  const updateQuery = (project: string, name: string, expression: string) => {
    if (project) {
      const request = async () => {
        try {
          await httpPost("admin", {
            action: "updatequery",
            name: name,
            expression: expression,
          });

          setActiveLabels((prev) => {
            const preexisting = prev ? prev[name] : undefined;
            if (preexisting) {
              if (preexisting.expression) {
                let next = { ...prev };
                if (expression) {
                  next[name] = { ...preexisting, expression: expression };
                } else {
                  delete next[name];
                }
                return next;
              }
              return prev;
            } else {
              let next = { ...prev };
              next[name] = {
                name: name,
                display: name,
                expression: expression,
                index: prev ? Object.keys(prev).length : 0,
              };
              return next;
            }
          });
        } catch (err) {}
      };

      if (project) {
        request();
      }
    }
  };

  const handleCreate = (name: string, value: string) => {
    let newItems = [
      ...items,
      {
        name: name,
        value: value,
        warn: isShadowedByRegularLabel(name)
          ? "Shadowed by existing label with same name."
          : undefined,
      },
    ];
    setItems(newItems);
  };

  const handleEdit = (name: string, value: string, index: number) => {
    if (value && value.trim() && !riki.validate_expression(value.trim())) {
      return false;
    }

    let newItems = [...items];
    newItems[index] = {
      name: name,
      value: value,
      warn: isShadowedByRegularLabel(name)
        ? "Shadowed by existing label with same name."
        : undefined,
    };
    setItems(newItems);

    updateQuery(project, name, value);
  };

  const handleDelete = (index: number) => {
    updateQuery(project, items[index].name, "");

    let newItems = items.slice(0, index).concat(items.slice(index + 1));
    setItems(newItems);
  };

  return (
    <Stack direction={"column"}>
      <Typography fontWeight={"bold"} sx={{ m: 2 }} fontSize={14}>
        User Defined Queries:
      </Typography>
      <KeyValueConfig
        items={items}
        onCreate={handleCreate}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
