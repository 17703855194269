import { useEffect, useState } from "react";
import Graph from "graphology";
import {
  SigmaContainer,
  useLoadGraph,
  useSigma,
  useRegisterEvents,
  useCamera,
  ControlsContainer,
} from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import circular from "graphology-layout/circular";
import forceAtlas2 from "graphology-layout-forceatlas2";
import { cropToLargestConnectedComponent } from "graphology-components";
// import { drawHover } from "./canvas-utils";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { useHttpPost } from "./hooks";

import { GraphSearchControl, GraphData } from "./GraphSearchControl";

import { storyFilterBoxState } from "./JotaiAtoms";

import {
  projectPickerState,
  timeFilterState,
  negateLabelsState,
  reloadState,
} from "./JotaiAtoms";
import { useAtomValue } from "jotai";

import * as lodash from "lodash";
import { Typography } from "@mui/material";
import { DarkStoryPreview } from "./StoryDetails";
import log from "./logger";
import { DEFAULT_FONT_SIZE } from "./constants";
import { StoryItem } from "./StoryItem";
import { StringMapKey } from "aws-sdk/clients/finspacedata";

function getMouseLayer() {
  return document.querySelector(".sigma-mouse");
}

function LoadGraph({ data, rankby }: { data?: GraphData; rankby?: string }) {
  const loadGraph = useLoadGraph();

  const reload = useAtomValue(reloadState);
  const { zoomIn, zoomOut, reset, goto, gotoNode } = useCamera();

  useEffect(() => {
    reset();
  }, [reload]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const graph = new Graph();
    const colors = [
      "grey",
      "lightsteelblue",
      "cornflowerblue",
      "thistle",
      "lightpink",
      "indianred",
    ];

    for (let [k, v] of Object.entries(data.nodes)) {
      const weight = v[0];
      const colorIdx = lodash.clamp(
        Math.round(weight * (colors.length - 1)),
        0,
        colors.length - 1
      );
      graph.addNode(k, {
        size: weight * 30 + 1,
        label: v[1] ? v[1] : k,
        color: colors[colorIdx],
      });
    }

    for (let e of data.edges) {
      graph.addEdge(e[0], e[1]);
    }

    cropToLargestConnectedComponent(graph);
    circular.assign(graph);
    const settings = forceAtlas2.inferSettings(graph);
    forceAtlas2.assign(graph, { settings, iterations: 600 });

    loadGraph(graph);
  }, [data]);

  return null;
}

type GraphEventsControllerProps = {
  setHoveredNode: (node: string) => void;
  onStoryClicked: (storyId: string) => void;
  children?: any;
};
function GraphEventsController({
  setHoveredNode,
  onStoryClicked,
  children,
}: GraphEventsControllerProps) {
  const sigma = useSigma();
  const graph = sigma.getGraph();
  const registerEvents = useRegisterEvents();

  useEffect(() => {
    registerEvents({
      clickNode({ node }) {
        if (!graph.getNodeAttribute(node, "hidden")) {
          if (onStoryClicked) {
            const label = graph.getNodeAttribute(node, "label");
            onStoryClicked(label as string);
          }
        }
      },
      enterNode({ node }) {
        setHoveredNode(node);
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.add("mouse-pointer");
      },
      leaveNode() {
        setHoveredNode("");
        // TODO: Find a better way to get the DOM mouse layer:
        const mouseLayer = getMouseLayer();
        if (mouseLayer) mouseLayer.classList.remove("mouse-pointer");
      },
    });
  }, []);

  return <>{children}</>;
}

function useDebounce<Type>(value: Type, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value !== debouncedValue) setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type GraphSettingsControllerProps = {
  hoveredNode: string;
  children?: any;
  onHover: (storyId: string) => void;
};

function GraphSettingsController({
  hoveredNode,
  children,
  onHover,
}: GraphSettingsControllerProps) {
  const sigma = useSigma();
  const graph = sigma.getGraph();

  const debouncedHoveredNode = useDebounce(hoveredNode, 40);

  const NODE_FADE_COLOR = "#bbb";
  const EDGE_FADE_COLOR = "#eee";

  useEffect(() => {
    const prev = sigma.getSetting("hoverRenderer");
    sigma.setSetting("hoverRenderer", (context, data, settings) => {
      if (prev) {
        prev(context, data, settings);
      }
      // drawHover(
      //   context,
      //   { ...sigma.getNodeDisplayData(data.key), ...data },
      //   settings
      // );
      if (onHover && data.hovered) {
        onHover(data.key);
      }
    });
  }, [sigma, graph]);

  useEffect(() => {
    // const hoveredColor = debouncedHoveredNode ? sigma.getNodeDisplayData(debouncedHoveredNode)!.color : "";
    const hoveredColor = debouncedHoveredNode ? "red" : "";

    sigma.setSetting(
      "nodeReducer",
      debouncedHoveredNode
        ? (node, data) =>
            node === debouncedHoveredNode ||
            graph.hasEdge(node, debouncedHoveredNode) ||
            graph.hasEdge(debouncedHoveredNode, node)
              ? {
                  ...data,
                  zIndex: 1,
                  highlighted: true,
                  hovered: node === debouncedHoveredNode,
                }
              : {
                  ...data,
                  zIndex: 0,
                  label: "",
                  color: NODE_FADE_COLOR,
                  image: null,
                  highlighted: false,
                }
        : null
    );
    sigma.setSetting(
      "edgeReducer",
      debouncedHoveredNode
        ? (edge, data) =>
            graph.hasExtremity(edge, debouncedHoveredNode)
              ? { ...data, color: hoveredColor, size: 4 }
              : { ...data, color: EDGE_FADE_COLOR, hidden: true }
        : null
    );
  }, [debouncedHoveredNode]);

  return <>{children}</>;
}

type GraphContainerProps = {
  data?: GraphData;
  onStoryClicked: (storyId: string) => void;
  rankby?: string;
  onHover: (storyId: string) => void;
  children: any;
};

function GraphContainer({
  data,
  onStoryClicked,
  rankby,
  onHover,
  children,
}: GraphContainerProps) {
  const [hoveredNode, setHoveredNode] = useState("");
  return (
    <SigmaContainer>
      <GraphSettingsController hoveredNode={hoveredNode} onHover={onHover} />
      <GraphEventsController
        setHoveredNode={setHoveredNode}
        onStoryClicked={onStoryClicked}
      />
      <LoadGraph data={data} rankby={rankby} />
      <ControlsContainer position={"top-right"} style={{ border: 0 }}>
        <GraphSearchControl data={data} />
      </ControlsContainer>
      {children}
    </SigmaContainer>
  );
}

type StoryGraphProps = {
  onStoryClicked: (storyId: string) => void;
  rankby?: string;
  onHover: (storyId: string) => void;
  children: any;
};

function StoryGraph({
  onStoryClicked,
  rankby,
  onHover,
  children,
}: StoryGraphProps) {
  const [data, setData] = useState();
  const project = useAtomValue(projectPickerState);
  const timeFilter = useAtomValue(timeFilterState);
  const filters = useAtomValue(storyFilterBoxState);
  const negateLabels = useAtomValue(negateLabelsState);
  const httpPost = useHttpPost();

  const getData = async (
    project: string,
    filters: string[],
    timeFilter: string,
    rankby?: string,
    negateLabels?: boolean
  ) => {
    try {
      const response = await httpPost("stories", {
        edges: true,
        period: timeFilter,
        label: filters,
        rankby: rankby,
        project: project,
        negate: negateLabels,
      });
      setData(response.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (project && filters) {
      const projFilters = project in filters ? filters[project] : [];
      getData(project, projFilters, timeFilter, rankby, negateLabels);
    }
  }, [project, timeFilter, filters, rankby, negateLabels]);

  return (
    <GraphContainer
      data={data}
      onStoryClicked={onStoryClicked}
      rankby={rankby}
      onHover={onHover}
    >
      {children}
    </GraphContainer>
  );
}

function Rank({ onRank }: { onRank: (rankby: string) => void }) {
  const choices = ["connectivity", "size", "age", "freshness"];
  const [rankby, setRankby] = useState(choices[0]);

  const handleChange = (e: React.ChangeEvent<HTMLElement>) => {
    setRankby((e.target as any).value);
    onRank((e.target as any).value);
  };

  useEffect(() => {
    onRank(choices[0]);
  }, []);

  return (
    <>
      <FormControl>
        <FormLabel id="graph-rankby-radio-buttons-group-label">
          Rank by
        </FormLabel>
        <RadioGroup
          aria-labelledby="graph-rankby-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          value={rankby}
          onChange={handleChange}
        >
          {choices.map(function (value) {
            return (
              <FormControlLabel
                key={value}
                value={value}
                control={
                  <Radio
                    size="small"
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 10,
                      },
                    }}
                  />
                }
                label={
                  <Typography fontSize={DEFAULT_FONT_SIZE}>{value}</Typography>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
}

function LeftPane({ hoverStory }: { hoverStory?: string }) {
  const [preview, setPreview] = useState<StoryItem>();
  const httpPost = useHttpPost();

  useEffect(() => {
    const getPreview = async () => {
      if (!hoverStory) {
        return;
      }

      try {
        const response = await httpPost("stories", {
          stories: true,
          story: hoverStory,
        });
        setPreview(response.data.stories[0]);
      } catch (err) {
        log.debug(err);
      }
    };
    getPreview();
  }, [hoverStory]);

  return (
    <Stack direction={"column"} sx={{ maxWidth: 300 }}>
      {preview && (
        <DarkStoryPreview item={preview} fontSize={11} key={preview.SK} />
      )}
    </Stack>
  );
}

function GraphView({
  onStoryClicked,
}: {
  onStoryClicked: (storyId: string) => void;
}) {
  const [rankby, setRankby] = useState<string>();
  const [hoverStory, setHoverStory] = useState<string>();

  const handleChange = (rankby: string) => {
    setRankby(rankby);
  };

  const handleHover = (storyId: string) => {
    setHoverStory(storyId);
  };

  return (
    <Stack direction={"row"} sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <StoryGraph
          onStoryClicked={onStoryClicked}
          rankby={rankby}
          onHover={handleHover}
        >
          <ControlsContainer
            position={"top-left"}
            style={{
              border: 0,
              backgroundColor: "transparent",
              height: "100%",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent={"space-between"}
              sx={{ height: "100%" }}
            >
              <Rank onRank={handleChange} />
              <Box sx={{ overflowY: "auto" }}>
                <LeftPane hoverStory={hoverStory} />
              </Box>
            </Stack>
          </ControlsContainer>
        </StoryGraph>
      </Box>
    </Stack>
  );
}

export default GraphView;
