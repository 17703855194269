import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";

const StyledSwitch = styled(Switch, {
  shouldForwardProp: (prop) =>
    prop !== "colorUnchecked" && prop !== "colorChecked",
})(({ theme, colorUnchecked, colorChecked }) => {
  const checked = colorChecked
    ? {
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: colorChecked,
          "&:hover": {
            backgroundColor: alpha(
              colorChecked,
              theme.palette.action.hoverOpacity
            ),
          },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
          backgroundColor: colorChecked,
        },
      }
    : {};

  const unchecked = colorUnchecked
    ? {
        "& .MuiSwitch-switchBase": {
          color: colorUnchecked,
          "&:hover": {
            backgroundColor: alpha(
              colorUnchecked,
              theme.palette.action.hoverOpacity
            ),
          },
        },
        // "& .MuiSwitch-switchBase + .MuiSwitch-track": {
        //   backgroundColor: color,
        // },
      }
    : {};

  return { ...checked, ...unchecked };
});

export function ColoredSwitch({
  colorChecked,
  colorUnchecked,
  onChange,
  checked,
  inputProps,
  size,
}) {
  return (
    <StyledSwitch
      colorChecked={colorChecked}
      colorUnchecked={colorUnchecked}
      onChange={onChange}
      checked={checked}
      inputProps={inputProps}
      size={size}
    />
  );
}
