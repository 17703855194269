import { atom } from "jotai";

import * as u from "./utility";

import store from "store2";

import { DummyStory, StoryItem } from "./StoryItem";

function getCurrentProject(): string {
  return (
    u.projectFromLocation() ||
    (window.location.pathname === "/"
      ? store.get("ProjectPicker:" + window.location.search, "") || ""
      : "")
  );
}

export const projectPickerState = atom<string>(getCurrentProject());

function createCachedAtom<T>(name: string, defaultValue: T) {
  const plainAtom = atom<T>();

  return atom(
    (get): T => {
      const proj: string = get(projectPickerState);
      const key = u.generateProjectCacheKey(name, proj);
      const cached = store.get(key, defaultValue) as T;
      const prev = get(plainAtom);
      return prev === undefined || prev !== cached ? cached : prev;
    },
    (get, set, newValue: T) => {
      const proj = get(projectPickerState);
      const key = u.generateProjectCacheKey(name, proj);
      store(key, newValue);
      set(plainAtom, newValue);
    }
  );
}

export const debugToggleState = createCachedAtom<boolean>("DebugToggle", false);

export const negateLabelsState = createCachedAtom<boolean>(
  "NegateLabels",
  false
);

export const labelToggleState = createCachedAtom<boolean>("LabelToggle", false);

export const imageToggleAtom = createCachedAtom<boolean>("ImageToggle", false);

export const timeFilterState = createCachedAtom<string>(
  "TimeFilter",
  "all time"
);

const readAllProjectCaptions = (): {
  [key: string]: { [key: string]: string };
} => {
  return JSON.parse(store.get("Captions", "{}"));
};
export const captionsState = atom<{ [key: string]: { [key: string]: string } }>(
  readAllProjectCaptions()
);

export const peopleDataState = atom<
  {
    SK: string;
    url?: string;
    avatar?: string;
    app: string;
    name?: string;
    username?: string;
    email?: string;
  }[]
>();

export const searchState = atom<string>("");

export const newStoriesState = atom<(StoryItem | DummyStory)[]>([]);

export const mergedStoriesState = atom<string[]>([]);

export const joinStoriesState = atom<{ [key: string]: boolean }>({});

export const reloadState = atom<number>(0);

export const showSelectedStoriesState = atom<boolean>(false);

export const menuCategoriesList = [
  "Stories",
  "Popular",
  "Recent",
  "Events",
  "Graph",
  "Artifacts",
  "Deleted",
  "People",
  "Vitals",
  "Settings",
] as const;

export type MenuCategory = (typeof menuCategoriesList)[number];
export const selectedCategory = atom<MenuCategory>("Stories");

export const storyFilterBoxState = atom<{ [key: string]: string[] }>();

export const activeLabelsState = atom<{
  [key: string]: {
    name?: string;
    display?: string | { icon: string };
    index: number;
    expression?: string;
  };
}>();
