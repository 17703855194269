import React, { useEffect, useRef, useState, memo, useCallback } from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as lodash from "lodash";
import { useDebounce } from "use-debounce";
import parse from "html-react-parser";

import { FileUploadPreview } from "./FileUploadPreview";
import { PasswordInput } from "./Password";
import * as u from "./utility";
import log from "./logger";
import { encrypt } from "./crypto";
import { Cipher } from "./Cipher";
import {
  useHttpPostAndProject,
  useUploadFile,
  useProjectCaptions,
  useHttpPost,
} from "./hooks";

import fuzzysort from "fuzzysort";
import { DarkStoryPreview } from "./StoryDetails";
import { MeddledFile } from "./Pdf";
import { StoryItem } from "./StoryItem";
import { MessageItem } from "./MessageItem";
import * as heic from "heic-convert/browser";
import { MAX_FILE_SIZE_PREVIEW } from "./constants";

export const MAX_MESSAGE_SIZE = 4000;

export type StoryRef = {
  PK: string;
  SK: string;
};

export type DummyItem = {
  PK?: string;
  SK: string;
  st_cap?: string;
  st_ui?: any;
  event_ts?: string;
  type?: string;
  refs?: StoryRef[];
  dummy?: boolean;
  text?: string;
  files?: unknown[];
  ts?: string;
};

export const convertHeicFiles = async (files: FileList | File[]) => {
  let convertedFiles: File[] = [];

  for (let f of files) {
    if (f.type === "image/heic") {
      const buffer = new Uint8Array(await f.arrayBuffer());
      const images = await heic.all({ format: "JPEG", buffer, quality: 0.8 });

      for (let img of images) {
        const outputBuffer = await img.convert();
        const name = f.name + ".jpeg";
        const out = new File([outputBuffer], name, { type: "image/jpeg" });
        convertedFiles.push(out);
      }
    }
  }

  return convertedFiles;
};

export const sortMessages = (messages: MessageItem[], order: boolean) => {
  if (!messages) {
    return messages;
  }

  let sorted = Array.from(messages);
  sorted.sort((a, b) => {
    const lt = order ? -1 : 1;
    const ka = a.st_edit ? a.st_edit + a.SK : a.SK;
    const kb = b.st_edit ? b.st_edit + b.SK : b.SK;

    if (ka < kb) {
      return lt;
    } else if (ka === kb) {
      return 0;
    } else {
      return -lt;
    }
  });
  return sorted;
};

function UploadErrorToast({ onRetry }: { onRetry: () => void }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography fontSize={12}>File upload error</Typography>
      <Button onClick={onRetry} size="small">
        RETRY
      </Button>
    </Stack>
  );
}

type UpdateRefsFunc = (prev: StoryRef[]) => StoryRef[];

export function handleFilesFromPasteEvent(
  e: React.ClipboardEvent<HTMLInputElement>,
  processFiles: (files: File[]) => void,
  setRefs: (arg: StoryRef[] | UpdateRefsFunc) => void
) {
  let clipboardItems = e.clipboardData.items;
  let files: File[] = [];

  let numFileAttachments = 0;

  // first handle files and text
  for (let i = 0; i < clipboardItems.length; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "file") {
      const file = item.getAsFile();
      if (file) {
        files.push(file);
        numFileAttachments += 1;
      }
    } else if (item.kind === "string") {
      if (item.type === "text/plain") {
        const txt = e.clipboardData.getData("text");
        if (txt.length > MAX_MESSAGE_SIZE) {
          const largeTextFile = new File([txt], "description.txt", {
            type: "text/plain",
          });
          files.push(largeTextFile);
        }
      }
    }
  }

  // lastly handle html. the processFiles has to be called once
  for (let i = 0; i < clipboardItems.length && numFileAttachments === 0; ++i) {
    const item = clipboardItems[i];
    if (item.kind === "string") {
      if (item.type === "text/html") {
        const downloadHtmlImages = async (
          images: HTMLCollectionOf<HTMLImageElement>,
          files: File[]
        ) => {
          for (let i = 0; i < images.length; ++i) {
            const img = images.item(i);
            if (!img) {
              continue;
            }

            const getTopstorieName = (img: HTMLImageElement) => {
              const attr = img.attributes.getNamedItem("st_filename");
              return attr ? attr.nodeValue : undefined;
            };

            try {
              const response = await fetch(img.src);
              const data = await response.blob();
              if (data.type.startsWith("image/")) {
                let nameFromUrl =
                  getTopstorieName(img) ||
                  (!img.src.startsWith("data:") && img.src.split("/").pop()) ||
                  uuidv4();

                nameFromUrl = u.sanitizeFileName(nameFromUrl);

                const filetype = data.type.split("/").pop();
                if (!nameFromUrl.endsWith("." + filetype)) {
                  nameFromUrl += "." + filetype;
                }

                const imageFile = new File([data], nameFromUrl, {
                  type: data.type,
                });
                files.push(imageFile);
              }
            } catch (e) {
              log.debug(e);
            }
          }

          if (files.length > 0) {
            processFiles(files);
          }
        };

        const parser = new DOMParser();
        const html = e.clipboardData.getData("text/html");
        const doc = parser.parseFromString(html, "text/html");
        if (doc.images.length > 0) {
          downloadHtmlImages(doc.images, files);
          files = [];
        }

        let refs: StoryRef[] = [];
        extractRefsFromHtml(doc, refs);

        if (refs.length > 0) {
          setRefs((prev: StoryRef[] | undefined) => {
            let res: StoryRef[] = [];
            let seen = new Set();

            const proc = (refs: StoryRef[]) => {
              for (let r of refs) {
                const key = r.PK + ":" + r.SK;
                if (!seen.has(key)) {
                  seen.add(key);
                  res.push(r);
                }
              }
            };

            if (prev) {
              proc(prev);
            }

            proc(refs);
            return prev && prev.length === res.length ? prev : res;
          });
        }
      }
    }
  }

  if (files.length > 0) {
    processFiles(files);
  }

  if (files.length === clipboardItems.length) {
    e.preventDefault();
  }
}

function TextToast({ text }: { text: string }) {
  return <Typography fontSize={12}>{text}</Typography>;
}

export function validateNotifyFileSize(file: File) {
  if (file.size > 128 * 1024 * 1024) {
    let abbrev = file.name;
    if (abbrev.length > 16) {
      abbrev = abbrev.slice(0, 13) + "...";
    }

    const text = `Sorry, the file "${abbrev}" is too big.`;
    toast.error(<TextToast text={text} />);
    return false;
  }

  return true;
}

function extractRefsFromHtml(doc: Document | Element, refs: StoryRef[]) {
  for (const child of doc.children) {
    const pk = child.attributes.getNamedItem("st_pk");
    if (pk && pk.nodeValue) {
      const sk = child.attributes.getNamedItem("st_sk");
      if (sk && sk.nodeValue) {
        const ref = { PK: pk.nodeValue, SK: sk.nodeValue };

        const found = refs.find((e) => lodash.isEqual(ref, e));
        if (!found) {
          refs.push(ref);
        }
      }
    }
    extractRefsFromHtml(child, refs);
  }
}

const getCacheKey = (item: StoryItem) => {
  const key = `SendMessageBox:${item.PK}:${item.SK}`;
  return key;
};

export const unfinishedMessageFromCache = (item: StoryItem): string => {
  const message = u.readStageStorage(getCacheKey(item), "");
  return message || "";
};

const saveMessageInCache = (item: StoryItem, message: string) => {
  u.writeStageStorage(getCacheKey(item), message);
};

const clearMessageInCache = (item: StoryItem) => {
  saveMessageInCache(item, "");
};

type FuzzyResult = {
  text: string;
  highlight: string;
};

const fuzzySearch = (input: string, captions: any): FuzzyResult[] => {
  const targets = Object.keys(captions);
  const res = fuzzysort.go(input, targets);
  return res.slice(0, 100).map((e) => {
    return {
      text: e.target,
      highlight: e.highlight("<font color='burlywood'><b>", "</b></font>"),
    };
  });
};

type SuggestionsProps = {
  input: string;
  captions: { [key: string]: string }; // Map<string, string>
  onSelected: (suggestion: string) => void;
  onHighlight?: (suggestion: string) => void;
  suggestionsRef?: { current?: FuzzyResult[] };
  sx: any;
  selectedIndex: number;
  noScrollIntoView?: boolean;
};

export function Suggestions({
  input,
  captions,
  onSelected,
  onHighlight,
  suggestionsRef,
  sx,
  selectedIndex,
  noScrollIntoView,
}: SuggestionsProps) {
  const [found, setFound] = useState<FuzzyResult[]>([]);
  const [highestRankingStory, setHighestRankingStory] = useState<StoryItem>();
  const httpPost = useHttpPost();
  const [hoverStory, setHoverStory] = useState<StoryItem>();
  const [debouncedInput] = useDebounce(input, 200);
  const menuRef = useRef<HTMLUListElement>(null);

  const searchStory = async (
    storyCaption: string
  ): Promise<StoryItem | undefined> => {
    try {
      const req = { search: true, query: storyCaption };
      const resp = await httpPost("stories", req);
      const searchResult = resp.data.search;
      if (searchResult) {
        return searchResult[0];
      }
    } catch (e) {}
    return undefined;
  };

  useEffect(() => {
    const input = debouncedInput;
    const found = fuzzySearch(input, captions);
    setFound(found);
    if (suggestionsRef) {
      suggestionsRef.current = found;
    }
    if (found.length > 0) {
      const request = async (storyCaption: string) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);
      request(found[index].text);
    } else {
      setHighestRankingStory(undefined);
      if (/^-?\d+$/.test(input)) {
        const request = async () => {
          const story = await searchStory(input);
          if (story) {
            setHighestRankingStory(story);

            const text = story.st_cap || input;
            const suggestions = [{ text: text, highlight: text }];
            setFound(suggestions);
            if (suggestionsRef) {
              suggestionsRef.current = suggestions;
            }
          }
        };
        request();
      }
    }
  }, [debouncedInput, captions]);

  useEffect(() => {
    if (found.length > 0) {
      const request = async (storyCaption: string) => {
        setHighestRankingStory(await searchStory(storyCaption));
      };
      const index = lodash.clamp(selectedIndex ?? 0, 0, found.length - 1);

      const text = found[index].text;
      request(text);

      if (onHighlight) {
        onHighlight(text);
      }

      if (menuRef && menuRef.current && !noScrollIntoView) {
        const child = menuRef.current.children[index];
        const y = Math.max(
          (child as any).offsetTop -
            menuRef.current.clientHeight +
            2 * child.clientHeight,
          0
        );
        menuRef.current.scroll(0, y);
      }
    }
  }, [selectedIndex, found]);

  const handleMouseEnter = (item: string) => {
    const request = async (storyCaption: string) => {
      setHoverStory(await searchStory(storyCaption));
    };
    request(item);
  };

  const handleMouseLeave = (item: string) => {
    // setHoverStory((prev) => prev === item ? undefined : prev);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const el = e.target as HTMLElement;
    // see [1167]
    if (el.tagName !== "B") {
      onSelected(el.innerText);
    } else {
      let parent = el.parentElement;
      if (
        parent &&
        parent.tagName === "FONT" &&
        parent.parentElement &&
        parent.parentElement.tagName === "DIV"
      ) {
        onSelected(parent.parentElement.innerText);
      } else {
        // TODO : do we want to raise an exception here?
        onSelected(el.innerText);
      }
    }
  };

  return (
    <>
      {found.length > 0 && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            backgroundColor: "#404040",
            color: "white",
            borderRadius: "5px",
            mt: 0.5,
            ...(sx ? sx : {}),
          }}
        >
          <Box sx={{ minWidth: "40%", overflow: "auto" }}>
            <MenuList
              // open={input ? true : false}
              ref={menuRef}
              sx={{ maxHeight: 300, overflow: "auto" }}
            >
              {found.map(({ text, highlight }, index) => {
                return (
                  <MenuItem
                    key={text}
                    onClick={handleClick}
                    sx={{ fontSize: 11 }}
                    onMouseEnter={() => handleMouseEnter(text)}
                    onMouseLeave={() => handleMouseLeave(text)}
                    selected={index === selectedIndex}
                  >
                    <div>{parse(highlight)}</div>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Box>
          {(hoverStory || highestRankingStory) && (
            <DarkStoryPreview
              item={hoverStory || highestRankingStory}
              fontSize={9}
            />
          )}
        </Stack>
      )}
    </>
  );
}

type SuggestionsPopperProps = {
  buffer: CursorBuffer;
  anchorRef?: { current?: HTMLElement };
  suggestionsRef: { current?: FuzzyResult[] };
  onSelected: (suggestion: string) => void;
  noScrollIntoView?: boolean;
  selectedIndex: number;
  onHighlight?: (suggestion: string) => void;
  options: { [key: string]: string };
};

export function SuggestionsPopper({
  buffer,
  anchorRef,
  suggestionsRef,
  onSelected,
  noScrollIntoView,
  selectedIndex,
  onHighlight,
  options,
}: SuggestionsPopperProps) {
  return (
    <>
      {buffer.text && anchorRef && anchorRef.current && (
        <Popper
          anchorEl={anchorRef.current}
          open={true}
          placement="bottom-start"
          style={{ zIndex: 10000 }}
          popperOptions={{
            modifiers: [
              {
                name: "flip",
                enabled: false,
              },
            ],
          }}
        >
          <Box sx={{ width: 500 }}>
            <Suggestions
              input={buffer.text}
              captions={options}
              onSelected={onSelected}
              suggestionsRef={suggestionsRef}
              selectedIndex={selectedIndex}
              noScrollIntoView={noScrollIntoView}
              onHighlight={onHighlight}
              sx={undefined}
            />
          </Box>
        </Popper>
      )}
    </>
  );
}

type CursorBuffer = {
  cursor?: number;
  text?: string;
  start?: number;
};

export function TextFieldWithSuggestions(props: any) {
  const [buffer, setBuffer] = useState<CursorBuffer>({});
  const suggestionsRef = useRef<FuzzyResult[]>([]);
  const inputRef = useRef();
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const captions = useProjectCaptions();

  const handleChange = (e: any) => {
    const cursor = e.target.selectionStart;
    if (e.nativeEvent.data === "[") {
      setBuffer({ start: cursor, cursor: cursor, text: "" });
    } else if (e.nativeEvent.data === "]") {
      setBuffer({});
    } else if (e.nativeEvent.inputType === "deleteContentBackward") {
      setBuffer((prev: CursorBuffer) => {
        if (prev.start && cursor >= prev.start && prev.cursor === cursor + 1) {
          const text = prev.text!.slice(0, -1);
          return { cursor: cursor, start: prev.start, text: text };
        } else {
          return {};
        }
      });
    } else {
      // check buffer length if it exceeds max caption size
      setBuffer((prev: CursorBuffer) => {
        if (prev.cursor && prev.cursor + 1 === cursor) {
          const text = prev.text + e.nativeEvent.data;
          return { cursor: cursor, start: prev.start, text: text };
        } else {
          return {};
        }
      });
      setSelectedSuggestion(0);
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  const selectPrev = () => {
    if (suggestionsRef.current && suggestionsRef.current.length) {
      const index = Math.max(0, selectedSuggestion - 1);
      setSelectedSuggestion(index);
    }
  };

  const selectNext = () => {
    if (suggestionsRef.current && suggestionsRef.current.length > 0) {
      const index = Math.min(
        suggestionsRef.current.length - 1,
        selectedSuggestion + 1
      );
      setSelectedSuggestion(index);
    }
  };

  const handleKeyDown = (e: any) => {
    if ((e.key === "Tab" || e.key === "Enter") && buffer.text) {
      // const suggest = fuzzySearch(buffer.text, captions);
      if (
        suggestionsRef &&
        suggestionsRef.current &&
        suggestionsRef.current.length > 0
      ) {
        const index = lodash.clamp(
          selectedSuggestion,
          0,
          suggestionsRef.current.length - 1
        );
        const suggestion = suggestionsRef.current[index].text;
        handleSuggestionSelected(suggestion);
        e.preventDefault();
      }
    } else if (e.key === "ArrowUp" || (e.ctrlKey && e.key === "k")) {
      selectPrev();
    } else if (e.key === "ArrowDown" || (e.ctrlKey && e.key === "j")) {
      selectNext();
    } else if (e.key === "Escape" && buffer.text) {
      setBuffer({});
    } else if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  }; // handleKeyDown

  const handleBlur = (e: any) => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      setBuffer({});
    }, 300);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleSuggestionSelected = (value: string) => {
    const ref = (props.inputRef || inputRef).current;
    ref.focus();

    const preText = ref.value.slice(0, buffer.start) + value + "]";
    const postText = ref.value.slice(buffer.cursor);
    ref.value = preText + postText;
    ref.setSelectionRange(preText.length, preText.length);

    if (props.setMessage) {
      props.setMessage(preText + postText);
    }
    setBuffer({});
  };

  const handleMouseDown = () => {
    setBuffer({});
  };

  const cleanProps = () => {
    let res = { ...props };
    delete res.setMessage;
    return res;
  };

  return (
    <>
      <TextField
        {...cleanProps()}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onMouseDown={handleMouseDown}
        inputRef={props.inputRef || inputRef}
      />
      <SuggestionsPopper
        anchorRef={props.inputRef || inputRef}
        buffer={buffer}
        suggestionsRef={suggestionsRef}
        onSelected={handleSuggestionSelected}
        selectedIndex={selectedSuggestion}
        options={captions}
      />
    </>
  );
}

type UpdateStringFunc = (str: string) => string;
export type NotifyMessage = { ts: string; msg: MessageItem; text?: string };

type SendMessageBoxProps = {
  item: StoryItem;
  setMessages: (arg: any) => void;
  id: string;
  onSent: (message?: NotifyMessage) => void;
  droppedFiles?: File[] | FileList;
  onFileRemoved: () => void;
  selectedFiles: File[];
  setSelectedFiles: (value: React.SetStateAction<File[]>) => void;
  message: string;
  setMessage: (message: string | UpdateStringFunc) => void;
  password: string;
  setPassword: (password: string) => void;
  previousMessage: string;
  onBlur: (event: any) => void;
  onFocus: () => void;
  focused: number;
};

export function SendMessageBox({
  item,
  setMessages,
  id,
  onSent,
  droppedFiles,
  onFileRemoved,
  selectedFiles,
  setSelectedFiles,
  message,
  setMessage,
  password,
  setPassword,
  previousMessage,
  onBlur,
  onFocus,
  focused,
}: SendMessageBoxProps) {
  const [disabled, setDisabled] = useState<boolean>(
    !unfinishedMessageFromCache(item)
  );
  const [refs, setRefs] = useState<StoryRef[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const inputRef = useRef<any>();
  const [visible, setVisible] = useState(false);
  const [httpPost] = useHttpPostAndProject();
  const uploadToS3 = useUploadFile();

  const processTextMessage = (value: string) => {
    setDisabled(value.length === 0 && selectedFiles.length === 0);
    const truncated = value.slice(0, MAX_MESSAGE_SIZE);
    setMessage(truncated);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    processTextMessage(e.target.value);
  };

  const handleSend = () => {
    if (
      (!message || message.length === 0) &&
      (!selectedFiles || selectedFiles.length === 0)
    ) {
      return;
    }

    const uploadFiles = async (
      ts: string,
      encrypted_message: Cipher | undefined
    ) => {
      const uploaded = [];
      for (let f of selectedFiles) {
        const uri = await uploadToS3(f);
        if (uri) {
          let uploaded_file = {
            uri: uri,
            type: f.type,
            size: f.size,
            name: f.name,
          };

          const meddled = f as MeddledFile;

          if (meddled.detected_mimetype) {
            uploaded_file.type = meddled.detected_mimetype;
          }
          uploaded.push(uploaded_file);
        } else {
          return false;
        }
      }

      const uploadRequest = async (message: string, files: unknown[]) => {
        try {
          const updateUiPreferences = (message: StoryItem) => {
            setTimeout(() => {
              httpPost("stories", { messages: true, story: item.SK })
                .then((response) => {
                  const latest = response.data.messages.find(
                    (e: StoryItem) => e.SK === message.SK
                  );
                  if (latest && latest.st_ui) {
                    setMessages((prev: StoryItem[]) => {
                      const found = prev.findIndex((e) => e.SK === message.SK);
                      if (found >= 0) {
                        let res = [...prev];
                        res[found] = { ...res[found], st_ui: latest.st_ui };
                        return res;
                      } else {
                        return prev;
                      }
                    });
                  }
                })
                .catch((e) => {
                  log.debug(e);
                });
            }, 3000);
          };

          type UploadRequest = {
            reply: boolean;
            story: string;
            files?: unknown[];
            refs: StoryRef[];
            enc?: Cipher;
            desc?: string;
            forward?: string;
          };

          let obj: UploadRequest = { reply: true, story: item.SK, refs: refs };
          if (files && files.length > 0) {
            obj.files = files;
          }

          if (encrypted_message) {
            obj.enc = encrypted_message;
          } else {
            if (message && message.length > 0) {
              obj.desc = message;
            }
          }

          if (item.st_source) {
            obj.forward = item.st_source;
          }

          httpPost("stories", obj).then((response) => {
            clearMessageInCache(item);
            if (onSent) {
              onSent({ ts: ts, msg: response.data.reply, text: obj.desc });
            }
            if (files && files.length > 0) {
              updateUiPreferences(response.data.reply);
            }
          });
        } catch (err) {
          log.debug(err);
        }
      };

      uploadRequest(message, uploaded);
      return true;
    }; // uploadFiles

    const ts = u.getCurrentTimestamp();
    let messageObj: DummyItem = {
      SK: ts,
      ts: ts,
      event_ts: ts,
      type: "message",
      refs: refs,
      dummy: true,
    };

    if (message && !password) {
      messageObj.text = message;
    }

    if (selectedFiles.length > 0) {
      messageObj.files = selectedFiles;
      messageObj.type = "topstorie_file_upload";
    }

    const proceed = async () => {
      let encrypted_message: Cipher | undefined = undefined;
      if (password && message) {
        encrypted_message = await encrypt(password, message);
      }

      const attemptUpload = async () => {
        const ok = await uploadFiles(ts, encrypted_message);
        if (ok) {
          if (onSent) {
            // update the thread, it will remove the dummy messages and
            // render pictures downloaded from cache, timeout is needed in order to give
            // the cache a bit of time to populate, because the cache is async
            setTimeout(() => onSent(), 2000);
          }

          return;
        }

        setMessages((prev: StoryItem[]) => {
          const found = prev.find((m: StoryItem) => m.SK === ts);
          if (found) {
            found.error = "upload error";
            return [...prev];
          }
          return prev;
        });

        toast.error(<UploadErrorToast onRetry={attemptUpload} />);
      };

      setMessages((prev: any[]) =>
        prev ? [...prev, messageObj] : [messageObj]
      );

      attemptUpload();
      setMessage("");
      setDisabled(true);
      setSelectedFiles([]);
      setPassword("");
    }; // proceed

    proceed();
  }; // handleSend

  const handleKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    } else if (e.key === "ArrowUp") {
      if (!message) {
        setMessage((prev: string) => {
          if (!prev) {
            return previousMessage;
          } else {
            return prev;
          }
        });
        e.preventDefault();
      }
    } else if (e.key === "Escape") {
      inputRef.current.blur();
    }
  }; // handleKeyDown

  const handleKeyUp = () => {}; // handleKey

  const processHeicFiles = async (files: FileList | File[]) => {
    let convertedFiles: File[] = [];

    for (let f of files) {
      if (f.type === "image/heic") {
        const buffer = new Uint8Array(await f.arrayBuffer());
        const images = await heic.all({ format: "JPEG", buffer, quality: 0.8 });

        for (let img of images) {
          const outputBuffer = await img.convert();
          const name = f.name + ".jpeg";
          const out = new File([outputBuffer], name, { type: "image/jpeg" });
          convertedFiles.push(out);
        }
      }
    }

    if (convertedFiles.length > 0) {
      processFiles(convertedFiles);
    }
  };

  const processFiles = (files: FileList | File[]) => {
    if (!files.length) {
      return;
    }

    const isDuplicate = (file: File) => {
      for (let f of selectedFiles) {
        if (
          file.name === f.name &&
          file.size === f.size &&
          file.type === f.type
        ) {
          return true;
        }
      }
      return false;
    };

    convertHeicFiles(files).then((converted) => {
      if (converted && converted.length > 0) {
        processFiles(converted);
      }
    });

    let newFiles = [];
    for (let fileToUpload of files) {
      // ignore heic files to be processed separately
      if (
        fileToUpload.type === "image/heic" &&
        fileToUpload.size < MAX_FILE_SIZE_PREVIEW
      ) {
        continue;
      }

      if (!isDuplicate(fileToUpload)) {
        if (validateNotifyFileSize(fileToUpload)) {
          newFiles.push(fileToUpload);
        }
      }
    }
    if (newFiles.length > 0) {
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  }; // processFiles

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      processFiles(e.target.files);
    }

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleDelete = useCallback(
    (index: number) => {
      setSelectedFiles((prev) => {
        let next = [...prev];
        next.splice(index, 1);
        return next;
      });
      if (onFileRemoved) {
        onFileRemoved();
      }
    },
    [setSelectedFiles, onFileRemoved]
  );

  const handleBlur = (e: any) => {
    // give a chance to other events to be handled
    // like when you click on a suggestion, that will immediately trigger blur
    // and you'll loose an event containing the clicked suggestion
    setTimeout(() => {
      if (!password) {
        saveMessageInCache(item, message);
      }
      if (onBlur) {
        onBlur(e);
      }
    }, 300);
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    handleFilesFromPasteEvent(e, processFiles, setRefs);
  };

  const handleRequestPassword = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((prev) => (prev ? undefined : e.currentTarget));
  };

  const handlePasswordCancel = () => {
    setAnchorEl(undefined);
  };

  const handleSetPassword = (password: string) => {
    setPassword(password);
    setAnchorEl(undefined);
    if (password) {
      clearMessageInCache(item);
    } else {
      saveMessageInCache(item, message);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setVisible(entry.isIntersecting);
      });
      observer.observe(inputRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (visible && focused > 0 && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [visible]);

  useEffect(() => {
    if (focused > 0 && visible && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, [focused]);

  useEffect(() => {
    if (droppedFiles && !password) {
      processFiles(droppedFiles);

      if (inputRef.current && visible) {
        inputRef.current.focus();
      }
    }
  }, [droppedFiles]);

  useEffect(() => {
    if (message || (selectedFiles && selectedFiles.length > 0)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedFiles, message]);

  return (
    <Stack width="100%">
      <TextFieldWithSuggestions
        setMessage={setMessage}
        label="Want to add anything to the story?"
        width="100%"
        size="small"
        value={message || ""}
        fullWidth
        multiline
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onPaste={handlePaste}
        variant="standard"
        id={anchorEl ? "color-picker-popper" : undefined}
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {true && (
                <>
                  <input
                    type="file"
                    id={`file-upload-${item.SK}-${id}`}
                    hidden
                    onChange={handleFileSelected}
                    multiple
                    disabled={!!password}
                  />
                  <label htmlFor={`file-upload-${item.SK}-${id}`}>
                    <IconButton component="span" disabled={!!password}>
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </>
              )}
              <IconButton
                // edge="end"
                color={password ? undefined : "primary"}
                disabled={selectedFiles.length > 0}
                onClick={handleRequestPassword}
                sx={{ color: password ? "red" : undefined }}
              >
                <KeyIcon />
              </IconButton>
              <IconButton
                edge="end"
                color="primary"
                disabled={disabled || (!!password && selectedFiles.length > 0)}
                onClick={handleSend}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            alignItems: "flex-start",
          },
        }}
      />
      <Popper
        id={anchorEl ? "color-picker-popper" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
      >
        <PasswordInput
          password={password}
          onPassword={handleSetPassword}
          onCancel={handlePasswordCancel}
          label={"Protect message with password"}
          error={undefined}
          confirm={true}
        />
      </Popper>
      <MemoFileUploadPreview
        item={item}
        files={selectedFiles}
        onDelete={handleDelete}
      />
    </Stack>
  );
}

const MemoFileUploadPreview = memo(FileUploadPreview);
