import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CropIcon from "@mui/icons-material/Crop";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";

import { toBase64 } from "fast-base64";
import PouchDB from "pouchdb-browser";
import * as lodash from "lodash";

import React, { useState, useEffect, useRef, useCallback, memo } from "react";

import { DEFAULT_FONT_SIZE } from "./constants";

import { ImageBox } from "./ImageBox";
import { MessageItem } from "./MessageItem";

import * as u from "./utility";
import {
  useDownloadFile,
  useSetMessageProps,
  useSilentDownload,
} from "./hooks";

import { useHttpPostAndProject } from "./hooks";

export const imageTypeWhitelist = new Set([
  "gif",
  "png",
  "jpg",
  "jpeg",
  "avif",
  "webp",
]);

const SIZE_STEP = 1.3;

type ButtonLinkProps = {
  text: string;
  onclick: (e: React.MouseEvent<HTMLElement>) => void;
  fontSize?: number;
  disabled?: boolean;
};
export function ButtonLink({
  text,
  onclick,
  fontSize,
  disabled,
}: ButtonLinkProps) {
  return (
    <Link
      component="button"
      variant="body2"
      onClick={(e) => {
        onclick(e);
      }}
      disabled={disabled}
    >
      <Typography fontSize={fontSize || DEFAULT_FONT_SIZE}>{text}</Typography>
    </Link>
  );
}

function getTextHeightPct(item: MessageItem, index: number) {
  const key = `${index}`;
  if (
    item &&
    item.st_ui &&
    "text_height" in item.st_ui &&
    key in item.st_ui.text_height
  ) {
    return item.st_ui.text_height[key] / 100;
  }

  return 0;
}

const getExplicitDownloadUrl = (url: string) => {
  const path = "slackfile?url=" + encodeURIComponent(url);
  return path;
};

const calcBaseHeight = (
  item: MessageItem,
  index: number,
  crop?: [number, number, number, number]
) => {
  const defaultHeight = 120;
  const textHeight = getTextHeightPct(item, index);
  let exact = textHeight > 0 ? Math.round(10 / textHeight) : defaultHeight;

  if (crop) {
    exact *= crop[3] - crop[1];
  }

  let result = exact;

  if (exact === defaultHeight) {
    return exact;
  }

  if (exact > defaultHeight) {
    let upper = defaultHeight;
    while (upper < exact) {
      upper *= SIZE_STEP;
    }
    const lower = upper / SIZE_STEP;
    result = upper - exact < exact - lower ? upper : lower;
  } else {
    let lower = defaultHeight;
    while (lower > exact) {
      lower /= SIZE_STEP;
    }
    const upper = lower * SIZE_STEP;
    result = upper - exact < exact - lower ? upper : lower;
  }
  return result;
};

function transformPoints(
  points: number[],
  scaleX: number,
  scaleY: number,
  translateX: number,
  translateY: number,
  round?: boolean
) {
  let res = [];
  if (round) {
    for (let i = 0; i < points.length; i += 2) {
      const x = Math.round(points[i] * scaleX + translateX);
      const y = Math.round(points[i + 1] * scaleY + translateY);
      res.push(x);
      res.push(y);
    }
  } else {
    for (let i = 0; i < points.length; i += 2) {
      const x = points[i] * scaleX + translateX;
      const y = points[i + 1] * scaleY + translateY;
      res.push(x);
      res.push(y);
    }
  }

  return res;
}

type Shape = {
  shape: any;
  points: number[];
};

function transformDrawing(
  drawing: Shape[],
  scaleX: number,
  scaleY: number,
  translateX: number,
  translateY: number,
  round?: boolean
): Shape[] {
  let res = [];
  for (let shape of drawing) {
    const points = transformPoints(
      shape.points,
      scaleX,
      scaleY,
      translateX,
      translateY,
      round
    );
    res.push({ shape: shape.shape, points: points });
  }
  return res;
}

type ImageAttrs = {
  name?: string;
  url?: string;
  mimetype?: string;
  filetype?: string;
  uploaded_file?: any;
};

type ImageProps = {
  image: ImageAttrs;
  sx?: any;
  item: MessageItem;
  index: number;
  name?: string;
  onLoad?: (e?: any) => void;
  fontSize?: number;
  readOnly?: boolean;
};

export function Image({
  image,
  sx,
  item,
  index,
  name,
  onLoad,
  fontSize,
  readOnly,
}: ImageProps) {
  const [data, setData] = useState<string>();
  const ref = useRef<{ url: null | string; prevClick: number }>({
    url: null,
    prevClick: 0,
  });
  const imgRef = useRef<HTMLElement>(null);
  const cropRef = useRef<HTMLElement>(null);
  const boxRef = useRef<HTMLElement>(null);

  const [mode, setMode] = useState<{ name?: string; transition?: boolean }>({});
  const [sizeFactor, setSizeFactor] = useState<number>(
    u.getImageSizeFactor(item, index)
  );
  const [commitedFactor, setCommitedFactor] = useState<number>(
    u.getImageSizeFactor(item, index)
  );
  const [publicUrl, setPublicUrl] = useState<string>();
  const [ext, setExt] = useState(u.getExt(image));
  const [wrongTypeOrError, setWrongTypeOrError] = useState<boolean>(false);
  // const [numErr, setNumErr] = useState(0);

  const [drawing, setDrawing] = useState<Shape[]>([]);
  // const [drawing, setDrawing] = useState([{shape: "line", points: [0, 0.1, 1, 0.1]}]);
  const [dims, setDims] = useState<[number, number]>();
  const [crop, setCrop] = useState<[number, number, number, number]>(
    JSON.parse(u.getUiProp(item, "crop", index, "[0, 0, 1, 1]"))
  );
  // const [crop, setCrop] = useState(null);

  const [httpPost, projectName] = useHttpPostAndProject();
  const downloadFileFromS3 = useDownloadFile();
  const downloadSilentlyForFutureUse = useSilentDownload();

  const setMessageProps = useSetMessageProps();

  const getImageName = () => {
    return name || image.name || "attachment";
  };

  const baseHeight = () => {
    return calcBaseHeight(item, index, crop);
  };

  const factoredHeight = () => {
    return (
      baseHeight() *
      sizeFactor *
      ((fontSize || DEFAULT_FONT_SIZE) / DEFAULT_FONT_SIZE)
    );
  };

  const getStyle = () => {
    if (mode.name === "maximized") {
      return { ...sx, maxWidth: "100%", height: "auto", width: "auto" };
    } else {
      return {
        ...sx,
        maxHeight: factoredHeight(),
        height: "auto",
        borderRadius: "7px",
        width: "auto",
        maxWidth: "100%",
      };
    }
  };

  /**
   *
   * @param {string} url
   * @param {string=} ext
   * @returns
   */
  const downloadImageFromS3 = async (url: string, ext: string) => {
    let key = "";
    const hash = await u.sha1(url);
    let content_type = ext ? "image/" + ext.toLowerCase() : null;

    if (ext) {
      // ext is always lower case and in cache we nee the ext from the url verbatum
      // if we can't get extension from the url then it's not there in the cache either
      const extFromUrl = u.getExtFromUrl(url);
      if (extFromUrl) {
        key = `${projectName}/${hash}.${extFromUrl}`;
      } else {
        key = `${projectName}/${hash}`;
      }

      if (!imageTypeWhitelist.has(ext)) {
        setWrongTypeOrError(true);
        return null;
      }
    } else {
      const metaKey = `${projectName}/${hash}.meta`;
      const bytes = await downloadFileFromS3(metaKey);
      if (bytes) {
        const meta = JSON.parse(bytes.toString());

        const type = meta.type.split("/").slice(0)[0];
        const ext = meta.type.split("/").slice(-1)[0];
        if (type === "image" && imageTypeWhitelist.has(ext)) {
          setExt(ext);
          key = `${projectName}/${hash}`;
          content_type = meta.type;
        } else {
          setWrongTypeOrError(true);
          key = "";
        }
      }
    }

    if (key) {
      const body = await downloadFileFromS3(key);
      if (!body) {
        return undefined;
      }

      const bodyBase64 = await toBase64(body);
      return { body: bodyBase64, content_type: content_type };
    }

    return undefined;
  };

  const downloadImage = async (url: string, ext: string) => {
    let failed = false;
    while (true) {
      try {
        // if (numErr > 0) {
        //   setNumErr((prev) => prev - 1);
        //   throw new Error();
        // }
        const downloaded = await downloadImageFromS3(url, ext);
        const base64String = downloaded ? downloaded.body : null;

        setData((prev?: string) =>
          base64String && base64String !== prev ? base64String : prev
        );
        setPublicUrl(undefined);
        setWrongTypeOrError(false);
        ref.current.url = url;

        const db = new PouchDB(projectName);
        let doc: any = {
          _id: url,
          _attachments: { file: { data: base64String } },
        };
        const content_type =
          image.mimetype ||
          (image.filetype && `image/${image.filetype}`) ||
          (downloaded && downloaded.content_type);
        if (content_type) {
          doc._attachments.file.content_type = content_type;
          db.get(doc._id)
            .then((prev: any) =>
              db.put({ _rev: prev._rev, ...doc }).catch((e) => {})
            )
            .catch((err) => db.put(doc).catch((e) => {}));
        }
        return;
      } catch (err) {
        if (failed) {
          break;
        }
        failed = true;
      }

      // this is last resort fallback
      // we are not really expected to get here
      try {
        // if (numErr > 0) {
        //   setNumErr((prev) => prev - 1);
        //   throw new Error();
        // }

        await httpPost(getExplicitDownloadUrl(url), null);
      } catch (err) {
        if (url.startsWith("s3://")) {
          setWrongTypeOrError(true);
        } else {
          setPublicUrl(url);
        }
        break;
      }
    }
  };

  const handleSizeUp = (e: React.MouseEvent<HTMLElement>) => {
    setSizeFactor((prev) => {
      return prev * SIZE_STEP;
    });
    e.stopPropagation();
  };

  const handleSizeDown = (e: React.MouseEvent<HTMLElement>) => {
    setSizeFactor((prev) => {
      return prev / SIZE_STEP;
    });
    e.stopPropagation();
  };

  const handleMouseEnter = () => {
    if (readOnly) {
      return;
    }

    setMode((prev) => (prev.name ? prev : { name: "controls" }));
  };

  const handleMouseLeave = () => {
    setMode((prev) => (prev.name === "controls" ? {} : prev));

    // dynamodb does not support floats, so we work around that limitation
    if (Math.abs(sizeFactor - commitedFactor) > 0.01) {
      const commit = Math.round(sizeFactor * 100);
      setMessageProps(item, `st_ui/f${index}`, commit);
      setCommitedFactor(commit / 100);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (readOnly) {
      return;
    }

    if (mode.name === "drawing" || mode.name === "cropping") {
      return;
    }

    if (event.shiftKey) {
      return;
    }

    const sinceLastClick =
      event.timeStamp - (ref.current.prevClick ? ref.current.prevClick : 0);
    ref.current.prevClick = event.timeStamp;

    if (event.metaKey || (event.detail === 2 && sinceLastClick < 200)) {
      const getImageData = () => {
        return `data:image/${ext};base64,${data}`;
      };
      if (u.detectBrowser() === "chrome") {
        let wnd = window.open("", "_blank");
        if (wnd) {
          wnd.document.open();
          wnd.document.write(`<img src="${getImageData()}"/>`);
          wnd.document.close();
        }
      } else {
        window.open(getImageData(), "_blank")?.focus();
      }
      setMode({ name: "controls" });
    } else {
      setMode((prev) => {
        return prev.name === "maximized"
          ? { name: "controls", transition: true }
          : { name: "maximized", transition: true };
      });
    }
    event.stopPropagation();
  };

  const readUploadedFile = async () => {
    try {
      const buffer = await image.uploaded_file.arrayBuffer();
      const u8 = new Uint8Array(buffer);
      const b64 = await toBase64(u8);
      setData((prev) => (b64 && b64 !== prev ? b64 : prev));
      setExt(u.getExt(image));
      return true;
    } catch (e) {}
    return false;
  };

  const readImageData = async () => {
    let ok = false;
    if (image.uploaded_file) {
      ok = await readUploadedFile();

      if (ok && image.url) {
        setTimeout(() => {
          if (image.url) {
            downloadSilentlyForFutureUse(image.url, u.getExt(image));
          }
        }, 2000);
      }
    }

    if (!ok && image.url && image.url !== ref.current.url) {
      const ext = u.getExt(image);
      const db = new PouchDB(projectName);
      db.get(image.url, { attachments: true })
        .then((doc: any) => {
          if (doc._attachments.file.content_type) {
            setData((prev) => {
              const fromCache = doc._attachments.file.data;
              return fromCache && fromCache !== prev ? fromCache : prev;
            });
            setExt(
              (prev) =>
                doc._attachments.file.content_type.split("/").slice(-1)[0]
            );
          } else {
            if (image.url) {
              downloadImage(image.url, ext);
            }
          }
        })
        .catch((err) => {
          if (image.url) {
            downloadImage(image.url, ext);
          }
        });
    }
  };

  // apply crop to the original drawing
  function loadDrawing(
    d: Shape[],
    dims: [number, number],
    crop: [number, number, number, number]
  ) {
    if (d && d.length > 0) {
      const [w, h] = dims;
      // given crop (0.1, 0.1, 0.6, 0.6)
      // coordinate transform
      // (0.1, 0.1) -> (0, 0)
      // (0.35, 0.35) -> (0.5, 0.5)
      // (0.6, 0.6) -> (1, 1)

      // translate
      const dx = crop ? -(crop[0] / (crop[2] - crop[0])) : 0;
      const dy = crop ? -(crop[1] / (crop[3] - crop[1])) : 0;

      // scale
      const sx = crop ? 1 / w / (crop[2] - crop[0]) : 1 / w;
      const sy = crop ? 1 / h / (crop[3] - crop[1]) : 1 / h;

      const scaled = transformDrawing(d, sx, sy, dx, dy);
      // setDrawing([{shape: "line", points: [0, 0, 1, 1]}]);

      setDrawing(scaled);
    } else {
      setDrawing([]);
    }
  }

  const handleLoad = (e: any) => {
    if (e && !dims) {
      const w = e.target.naturalWidth;
      const h = e.target.naturalHeight;
      setDims([w, h]);

      const drawing = JSON.parse(u.getUiProp(item, "drawing", index, "[]"));
      loadDrawing(drawing, [w, h], crop);

      if (onLoad) {
        onLoad(e);
      }
    }
  };

  const handleEdit = (e: React.MouseEvent<HTMLElement>) => {
    setMode({ name: "drawing" });
    e.stopPropagation();
  };

  const handleCrop = (e: React.MouseEvent<HTMLElement>) => {
    setMode({ name: "cropping" });
    e.stopPropagation();
  };

  const handleCloseCanvas = (drawing: Shape[]) => {
    setMode({ name: "controls" });
    setDrawing(drawing);
    // setDrawing([{shape: "line", points: [0.3, 0.3, 0.4, 0.3, 0.4, 0.4, 0.3, 0.4, 0.3, 0.3]}]);

    if (dims) {
      if (drawing) {
        // scale
        const sx = crop ? dims[0] * (crop[2] - crop[0]) : dims[0];
        const sy = crop ? dims[1] * (crop[3] - crop[1]) : dims[1];

        // translate
        const dx = crop ? crop[0] * dims[0] : 0;
        const dy = crop ? crop[1] * dims[1] : 0;

        const persisted = transformDrawing(drawing, sx, sy, dx, dy, true);

        // loadDrawing(persisted, dims);
        setMessageProps(
          item,
          `st_ui/drawing/${index}`,
          JSON.stringify(persisted)
        );
      } else {
        setMessageProps(item, `st_ui/drawing/${index}`, "[]");
      }
    }
  };

  const handleCropChanged = (newCrop?: [number, number, number, number]) => {
    const commitCrop = (crop: [number, number, number, number]) => {
      setMessageProps(
        item,
        `st_ui/crop/${index}`,
        JSON.stringify(crop.map((e) => lodash.round(e, 2)))
      );
    };

    if (newCrop) {
      if (dims && !u.equalArrays(newCrop, [0, 0, 1, 1])) {
        if (!crop) {
          loadDrawing(drawing, [1, 1], newCrop);

          commitCrop(newCrop);
          setCrop(newCrop);
        } else {
          loadDrawing(drawing, [1, 1], newCrop);

          // prev crop (0.1, 0.1, 0.5, 0.5)
          // new crop (0.5, 0.5, 1, 1)
          // result crop (0.35, 0.35, 0.5, 0.5)
          const x0 = crop[0] + newCrop[0] * (crop[2] - crop[0]);
          const y0 = crop[1] + newCrop[1] * (crop[3] - crop[1]);
          const x1 = crop[0] + newCrop[2] * (crop[2] - crop[0]);
          const y1 = crop[1] + newCrop[3] * (crop[3] - crop[1]);

          const resultCrop: [number, number, number, number] = [x0, y0, x1, y1];
          setCrop(resultCrop);
          commitCrop(resultCrop);
        }
      } else {
        if (drawing) {
          setDrawing([...drawing]);
        }
      }
    } else if (crop) {
      const sx = crop[2] - crop[0];
      const sy = crop[3] - crop[1];
      // const dx = crop[0];
      // const dy = crop[1];
      setCrop([0, 0, 1, 1]);
      commitCrop([0, 0, 1, 1]);

      if (drawing) {
        const originalScale = transformDrawing(
          drawing,
          sx,
          sy,
          crop[0],
          crop[1]
        );
        setDrawing(originalScale);
      }
    }

    setMode({ name: "controls" });
  };

  useEffect(() => {
    setSizeFactor(u.getImageSizeFactor(item, index));
  }, [item, index]);

  useEffect(() => {
    readImageData();
  }, [image]);

  const handleError = useCallback(() => {
    setWrongTypeOrError(true);
  }, [setWrongTypeOrError]);

  useEffect(() => {
    if (onLoad && (wrongTypeOrError || publicUrl)) {
      onLoad();
    }
  }, [wrongTypeOrError, publicUrl]);

  useEffect(() => {
    if (
      mode.transition &&
      (mode.name === "maximized" || mode.name === "controls") &&
      boxRef.current
    ) {
      boxRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }

    if (mode.name === "maximized") {
      const handleKey = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
          setMode({ name: "controls" });
        }
      };

      window.addEventListener("keydown", handleKey);

      return () => {
        window.removeEventListener("keydown", handleKey);
      };
    }
  }, [mode]);

  return (
    <>
      {!wrongTypeOrError && ext && (data || publicUrl) && (
        <Box
          component="span"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          ref={boxRef}
        >
          <Box
            sx={{ position: "relative", display: "inline-block" }}
            onClick={handleClick}
          >
            {mode.name === "controls" && (
              <>
                <Box sx={{ position: "absolute", left: 0, zIndex: 5 }}>
                  <Stack spacing={0} direction={"row"}>
                    <IconButton onClick={handleSizeUp} size="small">
                      <AddCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton onClick={handleSizeDown} size="small">
                      <RemoveCircleIcon
                        fontSize="inherit"
                        sx={{ borderRadius: "50%", background: "white" }}
                      />
                    </IconButton>
                    <IconButton onClick={handleEdit} size="small">
                      <EditIcon
                        fontSize="inherit"
                        sx={{
                          borderRadius: "50%",
                          background: "grey",
                          color: "white",
                        }}
                      />
                    </IconButton>
                    <IconButton onClick={handleCrop} size="small">
                      <CropIcon
                        fontSize="inherit"
                        sx={{
                          borderRadius: "50%",
                          background: "grey",
                          color: "white",
                        }}
                      />
                    </IconButton>
                  </Stack>
                </Box>
              </>
            )}

            {((drawing && drawing.length > 0) ||
              mode.name === "drawing" ||
              mode.name === "cropping") && (
              <>
                <Canvas
                  imgRef={imgRef}
                  cropRef={cropRef}
                  onClose={handleCloseCanvas}
                  onCropChanged={handleCropChanged}
                  initialDrawing={drawing}
                  mode={mode.name}
                  crop={crop}
                  style={{
                    opacity: 0.5,
                    position: "absolute",
                    zIndex: 4,
                    height: "100%",
                    width: "100%",
                  }}
                />
              </>
            )}

            {(!crop || u.equalArrays(crop, [0, 0, 1, 1])) && (
              <MemoImageBox
                refStory={item ? { st_pk: item.PK, st_sk: item.SK } : undefined}
                innerRef={imgRef}
                filetype={ext}
                base64Image={data}
                url={publicUrl}
                name={getImageName()}
                sx={{ ...getStyle() }}
                onError={handleError}
                onLoad={handleLoad}
                readOnly={readOnly}
              />
            )}

            {data && crop && !u.equalArrays(crop, [0, 0, 1, 1]) && (
              <Crop
                innerRef={cropRef}
                refStory={item ? { st_pk: item.PK, st_sk: item.SK } : undefined}
                filetype={ext}
                base64Image={data}
                url={publicUrl}
                name={getImageName()}
                sx={{ ...getStyle() }}
                onError={handleError}
                onLoad={handleLoad}
                crop={crop}
              />
            )}
          </Box>
        </Box>
      )}
      {wrongTypeOrError && (
        <>
          {image.url && image.url.startsWith("s3://") && (
            <Box sx={{ mr: 1 }}>
              <ButtonLink
                text={getImageName()}
                onclick={() => readImageData()}
              ></ButtonLink>
            </Box>
          )}
          {image.url && !image.url.startsWith("s3://") && (
            <Link sx={{ mr: 1 }} href={image.url}>
              {getImageName()}
            </Link>
          )}
        </>
      )}
    </>
  );
}

function clearCanvas(canvas: HTMLCanvasElement) {
  const context = canvas.getContext("2d");
  if (context) {
    context.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
    context.beginPath();
  }
  // context.canvas.width = context.canvas.width;
}

function drawOnCanvas(canvas: HTMLCanvasElement | null, drawing: Shape[]) {
  if (!canvas) {
    return;
  }

  if (drawing) {
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    const { devicePixelRatio: ratio = 1 } = window;
    const canvasWidth = Math.round(canvas.clientWidth * ratio);
    const canvasHeight = Math.round(canvas.clientHeight * ratio);
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    context.scale(ratio, ratio);
    context.beginPath();

    (canvas as any).shapes = [...drawing];

    context.lineWidth = (5 * canvas.height) / canvas.clientHeight;
    context.strokeStyle = "yellow";
    context.lineJoin = "round";
    context.lineCap = "round";

    for (let shape of (canvas as any).shapes) {
      for (let i = 0; i < shape.points.length; i += 2) {
        const x = shape.points[i] * canvas.clientWidth;
        const y = shape.points[i + 1] * canvas.clientHeight;
        if (i === 0) {
          context.beginPath();
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
          context.stroke();
        }
      }
    }
  } else {
  }
}

function drawCropRect(
  canvas: HTMLCanvasElement | null,
  start: [number, number],
  end: [number, number]
) {
  if (!canvas) {
    return;
  }

  const context = canvas.getContext("2d");
  if (!context) {
    return;
  }

  const [x, y] = end;

  clearCanvas(canvas);
  context.fillStyle = "yellow";
  context.strokeStyle = "yellow";

  const left = Math.min(x, start[0]);
  const right = Math.max(x, start[0]);
  const top = Math.min(y, start[1]);
  const bottom = Math.max(y, start[1]);

  context.rect(left, top, right - left, bottom - top);
  context.fill();
  context.stroke();
}

function getMouse(e: React.MouseEvent<HTMLElement>): [number, number] {
  const target = e.target as HTMLElement;
  const rect = target.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  return [x, y];
}

type CanvasProps = {
  imgRef: React.RefObject<HTMLElement>;
  cropRef: React.RefObject<HTMLElement>;
  style: any;
  onClose: (drawing: Shape[]) => void;
  initialDrawing: Shape[];
  crop: [number, number, number, number];
  mode?: string;
  onCropChanged: (crop?: [number, number, number, number]) => void;
};

function Canvas({
  imgRef,
  cropRef,
  style,
  onClose,
  initialDrawing,
  crop,
  mode,
  onCropChanged,
}: CanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [drawing, setDrawing] = useState<boolean>(false);
  const [cropStart, setCropStart] = useState<[number, number]>();
  const [cropEnd, setCropEnd] = useState<[number, number]>();
  const [controlsOff, setControlsOff] = useState<boolean>(false);

  const getDimsFromRef = (): [number, number] | undefined => {
    if (cropRef.current) {
      return [cropRef.current.clientWidth, cropRef.current.clientHeight];
    } else if (imgRef.current) {
      return [imgRef.current.clientWidth, imgRef.current.clientHeight];
    }

    return undefined;
  };

  useEffect(() => {
    // this is stupid but i think i have no other choice
    // the canvas will be resized by css and i want to know the final size
    // which i am not really sure how to otherwise obtain, so polling it is
    // i need this information to size the pen so it looks uniform regardelss
    // of crop and resolution
    const attempt = (i: number, prev?: [number, number]) => {
      if (i < 10 && prev) {
        const next = getDimsFromRef();
        const canvas = canvasRef.current;
        if ((!next || !prev || u.equalArrays(next, prev)) && canvas) {
          setTimeout(() => attempt(i + 1, next), 30);
        } else if (canvas) {
          drawOnCanvas(canvas, initialDrawing);
        }
      }
    };

    const canvas = canvasRef.current;
    drawOnCanvas(canvas, initialDrawing);
    attempt(0, getDimsFromRef());
  }, [initialDrawing, crop]);

  const handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    if (drawing) {
      setDrawing(false);
      e.stopPropagation();
      setControlsOff(false);
    } else if (cropStart) {
      const canvas = canvasRef.current;
      const mouse = getMouse(e);
      drawCropRect(canvas, cropStart, mouse);
      setCropEnd(mouse);
      e.stopPropagation();
      setControlsOff(false);
    }
  };

  const isEditable = (mode?: string) => {
    return mode === "drawing" || mode === "cropping";
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    if (!isEditable(mode)) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }

    const { devicePixelRatio: ratio = 1 } = window;
    const target = e.target as HTMLElement;
    const canvasWidth = Math.round(target.clientWidth * ratio);
    const canvasHeight = Math.round(target.clientHeight * ratio);

    // redraw canvas if it resizes
    if (canvas.width !== canvasWidth || canvas.height !== canvasHeight) {
      const { devicePixelRatio: ratio = 1 } = window;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context.scale(ratio, ratio);

      if ((canvas as any).shapes) {
        context.lineWidth = (5 * canvasHeight) / target.clientHeight;
        context.strokeStyle = "yellow";
        context.lineJoin = "round";
        context.lineCap = "round";

        for (let shape of (canvas as any).shapes) {
          for (let i = 0; i < shape.points.length; i += 2) {
            const x = shape.points[i] * target.clientWidth;
            const y = shape.points[i + 1] * target.clientHeight;
            if (i === 0) {
              context.beginPath();
              context.moveTo(x, y);
            } else {
              context.lineTo(x, y);
              context.stroke();
            }
          }
        }
      }
    }

    const [x, y] = getMouse(e);

    if (mode === "drawing") {
      e.stopPropagation();
      setDrawing(true);

      context.lineWidth = (5 * canvas.height) / target.clientHeight;
      context.strokeStyle = "yellow";
      context.lineJoin = "round";
      context.lineCap = "round";
      context.beginPath();
      context.moveTo(x, y);

      if (!(canvas as any).shapes) {
        (canvas as any).shapes = [];
      }

      const line = {
        shape: "line",
        points: [x / target.clientWidth, y / target.clientHeight],
      };
      (canvas as any).shapes.push(line);
      setControlsOff(true);
    } else if (mode === "cropping") {
      e.stopPropagation();
      setCropStart([x, y]);
      setCropEnd(undefined);
      setControlsOff(true);
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    if (drawing && e.buttons === 1) {
      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }

      const context = canvas.getContext("2d");
      if (!context) {
        return;
      }

      const target = e.target as HTMLElement;

      let line = (canvas as any).shapes[(canvas as any).shapes.length - 1];
      const originX = line.points[line.points.length - 2] * target.clientWidth;
      const originY = line.points[line.points.length - 1] * target.clientHeight;
      context.beginPath();
      context.moveTo(originX, originY);

      const [x, y] = getMouse(e);
      context.lineTo(x, y);
      context.stroke();

      line.points.push(x / target.clientWidth);
      line.points.push(y / target.clientHeight);
    } else if (cropStart && e.buttons === 1) {
      const canvas = canvasRef.current;
      const mouse = getMouse(e);
      drawCropRect(canvas, cropStart, mouse);
      setCropEnd(mouse);
    } else {
      setControlsOff(false);
    }
  };

  const handleEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (drawing && e.buttons === 1) {
      const canvas = canvasRef.current;
      // const context = canvas.getContext("2d");

      const target = e.target as HTMLElement;
      const rect = target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      if (!(canvas as any).shapes) {
        (canvas as any).shapes = [];
      }
      const line = {
        shape: "line",
        points: [x / target.clientWidth, y / target.clientHeight],
      };
      (canvas as any).shapes.push(line);
    }
  };

  const handleClearDrawing = () => {
    if (mode === "drawing") {
      if (canvasRef.current) {
        clearCanvas(canvasRef.current);
      }
      onClose([]);
    } else if (mode === "cropping") {
      setCropStart(undefined);
      setCropEnd(undefined);
      setDrawing(false);
      onCropChanged(undefined);
    }
  };

  const handleAcceptDrawing = () => {
    if (mode === "drawing") {
      const canvas = canvasRef.current;
      onClose((canvas as any).shapes || null);
    } else if (mode === "cropping") {
      if (cropStart && cropEnd) {
        const canvas = canvasRef.current;
        if (!canvas) {
          return;
        }

        const h = canvas.clientHeight;
        const w = canvas.clientWidth;

        const x0 = cropStart[0] / w;
        const y0 = cropStart[1] / h;
        const x1 = cropEnd[0] / w;
        const y1 = cropEnd[1] / h;

        let crop: [number, number, number, number] = [
          Math.min(x0, x1),
          Math.min(y0, y1),
          Math.max(x0, x1),
          Math.max(y0, y1),
        ];
        onCropChanged(crop);
      } else {
        onCropChanged([0, 0, 1, 1]);
      }

      setCropStart(undefined);
      setCropEnd(undefined);
      if (canvasRef.current) {
        clearCanvas(canvasRef.current);
      }
    }
  };

  const handleCancelCurrentDrawing = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      clearCanvas(canvas);
      drawOnCanvas(canvas, initialDrawing);
    }
    if (mode === "drawing") {
      onClose(initialDrawing);
      setDrawing(false);
    } else if (mode === "cropping") {
      setCropStart(undefined);
      setCropEnd(undefined);
      onCropChanged([0, 0, 1, 1]);
    }
  };

  useEffect(() => {
    if (isEditable(mode) && !controlsOff) {
      const handleKey = (e: KeyboardEvent) => {
        if (e.keyCode === 27) {
          handleCancelCurrentDrawing();
        }
      };

      window.addEventListener("keydown", handleKey);
      return () => {
        window.removeEventListener("keydown", handleKey);
      };
    }
  }, [mode, controlsOff]);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={style}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleEnter}
      />

      {isEditable(mode) && !controlsOff && (
        <Box sx={{ position: "absolute", left: 0, zIndex: 15 }}>
          <Stack direction={"row"}>
            <IconButton onClick={handleClearDrawing} size="small">
              <RemoveCircleIcon
                fontSize="inherit"
                sx={{ borderRadius: "50%", background: "white" }}
              />
            </IconButton>
            <IconButton onClick={handleCancelCurrentDrawing} size="small">
              <CancelIcon
                fontSize="inherit"
                sx={{ borderRadius: "50%", background: "white" }}
              />
            </IconButton>
            <IconButton onClick={handleAcceptDrawing} size="small">
              <CheckCircleIcon
                fontSize="inherit"
                sx={{ borderRadius: "50%", background: "white" }}
              />
            </IconButton>
          </Stack>
        </Box>
      )}
    </>
  );
}

type CropProps = {
  crop: [number, number, number, number];
  innerRef: any;
  onLoad: (e: any) => void;
  sx: any;
  onError: () => void;
  name: string;
  url?: string;
  base64Image: string;
  filetype: string;
  refStory?: { st_pk: string; st_sk: string };
};

function Crop({ crop, innerRef, ...rest }: CropProps) {
  const imgRef = useRef<HTMLElement>(null);
  const [dims, setDims] = useState<[number, number]>();

  const handleLoad = (e: any) => {
    if (rest.onLoad) {
      rest.onLoad(e);
    }

    const w = e.target.naturalWidth;
    const h = e.target.naturalHeight;
    setDims([w, h]);
  };

  useEffect(() => {
    if (dims) {
      const canvas = innerRef.current;
      const context = canvas.getContext("2d");

      // const crop = [0, 0.3, 1, 0.7];
      const { devicePixelRatio: ratio = 1 } = window;
      const cropWidth = dims[0] * (crop[2] - crop[0]);
      const cropHeight = dims[1] * (crop[3] - crop[1]);
      const canvasWidth = Math.round(cropWidth * ratio);
      const canvasHeight = Math.round(cropHeight * ratio);

      // redraw canvas if it resizes
      if (canvas.width !== canvasWidth || canvas.height !== canvasHeight) {
        const { devicePixelRatio: ratio = 1 } = window;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        context.scale(ratio, ratio);
        context.drawImage(
          imgRef.current,
          crop[0] * dims[0],
          crop[1] * dims[1],
          cropWidth,
          cropHeight,
          0,
          0,
          cropWidth,
          cropHeight
        );
      }
    }
  }, [dims, crop]);

  return (
    <>
      <MemoImageBox
        innerRef={imgRef}
        {...rest}
        sx={{ ...rest.sx, display: "none" }}
        onLoad={handleLoad}
      />
      <canvas ref={innerRef} style={rest.sx} />
    </>
  );
}

const MemoImageBox = memo(ImageBox);
