import React, { useEffect, useRef, useState, useMemo } from "react";
import * as u from "./utility";
import * as storydat from "./storydat";
import { useAtomValue } from "jotai";
import { projectPickerState, captionsState } from "./JotaiAtoms";
import {
  postHttpApi,
  getApiUrl,
  getHookUrl,
  uploadToS3,
  downloadFileFromS3Bucket,
} from "./Auth";
import { useLocation } from "react-router-dom";
import store from "store2";
import { AxiosResponse } from "axios";

export function useDoubleClick(onDoubleClick: () => void) {
  const ref = useRef(0);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const sinceLastClick = e.timeStamp - (ref.current ? ref.current : 0);

    ref.current = e.timeStamp;

    if (e.detail === 2 && sinceLastClick < 200) {
      onDoubleClick();
      e.stopPropagation();
    }
  };
  return handleClick;
}

export function useNewTabText(): [
  string,
  (text: string) => void,
  (e: React.MouseEvent<HTMLElement>) => void
] {
  const [textData, setTextData] = useState<string>("");

  const handleDoubleClick = useDoubleClick(() => {
    let wnd = window.open("", "_blank");
    if (wnd) {
      wnd.document.open();
      wnd.document.write(`<pre>${u.escapeHtml(textData)}</pre>`);
      wnd.document.close();
    }
  });

  return [textData, setTextData, handleDoubleClick];
}

type HttpPostFunc = (
  path: string,
  request: any
) => Promise<AxiosResponse<any, any>>;

export const useHttpPostAndProject = (): [HttpPostFunc, string] => {
  const project = useAtomValue(projectPickerState);
  const stage = useStage();

  return [
    (path: string, request: any) =>
      postHttpApi(getApiUrl(stage), path, { project: project, ...request }),
    project,
  ];
};

export const useHttpPost = (): HttpPostFunc => {
  return useHttpPostAndProject()[0];
};

export const useHttpApiPost = () => {
  const stage = useStage();

  return (path: string, request: any) =>
    postHttpApi(getApiUrl(stage), path, request);
};

export const useProjectCache = (
  key: string,
  defaultValue?: string
): [string, (value: string) => void, (value: string) => void] => {
  const project = useAtomValue(projectPickerState);
  const [cacheValue, setCacheValue] = useState<string>(defaultValue || "");
  useEffect(() => {
    setCacheValue(u.readStageStorage(`${key}:${project}`, "") || "");
  }, [project]);

  const updateCache = (value: string) => {
    setCacheValue(value);
    u.writeStageStorage(`${key}:${project}`, value);
  };

  return [cacheValue, updateCache, setCacheValue];
};

export const useSetMessageProps = () => {
  const httpPost = useHttpPost();

  const setProp = (
    item: { PK: string; SK: string; st_ui?: any },
    key: string,
    value: any
  ) => {
    if (!item) {
      return;
    }

    try {
      let request: any = {
        setmessageprops: {
          PK: item.PK,
          SK: item.SK,
        },
      };
      request.setmessageprops[key] = value;
      httpPost("label", request);
    } catch (err) {}
  };

  return setProp;
};

export const useExitToApp = () => {
  const httpPost = useHttpPost();

  const exitToApp = (item: any) => {
    if ("channel" in item || item.st_source === "slack") {
      // slack
      const request = async (myWindow: Window) => {
        try {
          const story = item.SK;
          let params: any = { exittoapp: true, story: story };
          if (item.channel) {
            params.channel = item.channel;
          }
          const response = await httpPost("stories", params);
          myWindow.location.href = response.data.exittoapp.url;
        } catch (err) {
        } finally {
        }
      };
      if ("permalink" in item) {
        window.open(item.permalink, "_blank", "noreferrer");
      } else {
        const myWindow = window.open();
        if (myWindow) {
          request(myWindow);
        }
      }
    } else {
      let url = storydat.getSourceUrl(item);

      if (url) {
        window.open(url, "_blank", "noreferrer");
      }
    }
  };
  return exitToApp;
};

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export function useStage(): string {
  let query = useQuery();
  return query.get("stage") || "prod2";
}

export function useHookUrl() {
  const stage = useStage();
  const project = useAtomValue(projectPickerState);
  return getHookUrl(stage) + project;
}

export function useUploadFile() {
  const stage = useStage();
  const project = useAtomValue(projectPickerState);

  return async (file: File) => {
    const userUploadBucket = `topstorie-user-uploads-${stage}`;
    return await uploadToS3(file, project, userUploadBucket);
  };
}

export function useDownloadFile() {
  const stage = useStage();
  return async (key: string) =>
    await downloadFileFromS3Bucket(key, `stumti-image-cache-eu-${stage}`);
}

export function useSilentDownload() {
  const downloadFileFromS3 = useDownloadFile();
  const project = useAtomValue(projectPickerState);

  return async (url: string, ext: string | undefined) => {
    try {
      const hash = await u.sha1(url);
      let key = `${project}/${hash}`;
      if (ext) {
        key = `${key}.${u.getExtFromUrl(url)}`;
      }
      await downloadFileFromS3(key);
    } catch {}
  };
}

export function useProjectCaptions(): { [key: string]: string } {
  const captions = useAtomValue(captionsState);
  const projectName = useAtomValue(projectPickerState);

  if (captions && captions[projectName]) {
    return captions[projectName];
  }

  return {};
}

export function useLocalStorage<Type>(
  name: string,
  initValue?: Type
): [Type | undefined, (value: Type | undefined) => void] {
  const [value, setValue] = useState<Type | undefined>(
    store.get(name, initValue)
  );

  type Func = (value: Type | undefined) => Type | undefined;
  return [
    value,
    (arg: Type | undefined) => {
      if (typeof arg === "function") {
        const newValue = (arg as Func)(value);
        store(name, newValue);
        setValue(newValue);
      } else if (arg === undefined) {
        store.remove(name);
        setValue(arg);
      } else {
        store(name, arg);
        setValue(arg);
      }
    },
  ];
}
